import { message } from 'antd';
import baseUrl from '../../config.json'


export const GetMonthData = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/eventShedule/GetCalendarDays`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Month: body,
                Year: "2024"
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetEvents = () => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/eventShedule/GetEventByUserCode`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const CreateEvent = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/eventShedule/InsertEvent `, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: body?.title,
                description: body?.description,
                Dates: body?.Dates,
                start_date: body?.start_date,
                end_date: body?.end_date,
                colour: body?.colour,
                remind_Email: body?.remind_Email,
                month:body?.month
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const DeleteEventFunction = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/eventShedule/DeleteEvent`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventid: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const UpdateEventFunction = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/eventShedule/UpdateEvent`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventid:body?.eventid,
                title:body?.title,
                description:body?.description,
                start_date:body?.start_date,
                end_date:body?.end_date,
                remind_Notification:body?.remind_Notification,
                remind_Email:body?.remind_Email
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}