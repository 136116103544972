import React,{useState,useEffect} from 'react'
import '../components/assets/css/websiteQuotations.css'
import OrderDataTable from 'react-data-table-component'
import secureLocalStorage from "react-secure-storage";
import SearchBar from './SearchBar';
import useClipboard from "react-use-clipboard";
import { useNavigate,useLocation  } from 'react-router-dom';
import { FiCopy as Copy_ico } from "react-icons/fi";
import { RiFileExcel2Fill as Excel_ico} from "react-icons/ri";
import { FiPrinter as Print_ico } from "react-icons/fi";
import { RxCross2 as Cross_ico } from "react-icons/rx";
import { Tooltip } from "react-tooltip";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import {useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { MdDeleteOutline as Delete_ico } from 'react-icons/md';
import { CiEdit as Update_ico } from 'react-icons/ci';
const config = require('../components/config.json')



function ViewTaskTimeCom() {
    const search = useLocation().search
    var id = new URLSearchParams(search).get('id')
    const [dataLoader, setDataLoader] = useState(false);
    const [loading, setLoading] = useState(true);

    const [isTaskTimeData, setTaskTimeData] = useState([]);
    const [isFilterTaskTimeData,setFilterTaskTimeData] = useState([])
    const [isGetTimeModal,setGetTimeModal] = useState(false)

    const [isFilterValue,setFilterValue] = useState("");
    const [error, setError,] = useState();
    const [page, setPage] = useState(0)

    const [Isrows, setRows] = useState([]);
    const navigate = useNavigate()
    var get_refresh_token = secureLocalStorage.getItem("refresh");
    var get_access_token = secureLocalStorage.getItem("access_token");
    var get_role = secureLocalStorage.getItem("role_id")
    const [isCopied, setCopied] = useClipboard(JSON.stringify(isTaskTimeData));
    const [btnloading, setbtnLoading] = useState(false);
    const [btnEnaledAndDisabled, setBtnEnaledAndDisabled] = useState("")

    const [isStartTime,setStartTime] = useState("")
    const [isEndTime,setEndTime] = useState("")
    const [isTodayDate,setTodayDate] = useState("")
    const [isTaskIds,setTaskIds] = useState({
        task_id : "",
        task_time_id: ""
    })


    const showAlert = (message, type) => {
        setError({
            message: message,
            type: type,
        })
    }

    async function getAllTheTimeofTask() {
        await fetch(`${config['baseUrl']}/timetask/getTimeTaskById/${id}/${parseInt(page)}`, {
            method: "GET",
            headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` }
        }).then((response) => {
            return response.json()
        }).then(async (response) => {
            if (response.messsage == "unauthorized") {
                await fetch(`${config['baseUrl']}/timetask/getTimeTaskById/${id}/${parseInt(page)}`, {
                    method: "GET",
                    headers: { "content-type": "application/json", "refereshToken": `Bareer ${get_refresh_token}` }
                }).then(response => {
                    return response.json()
                }).then(response => {
                    secureLocalStorage.setItem("refresh", response.referesh_token);
                    secureLocalStorage.setItem("access_token", response.access_token);
                    setTaskTimeData(response.data)
                    setFilterTaskTimeData(response.data)
                    setRows(response.totalRows)
                    setDataLoader(true)
                }).catch((errs) => {
                    showAlert(errs.message, "warning")
                }).finally(() => { setLoading(false) })
            }
            else if(response.messsage == "timeout error"){
                localStorage.clear()
                sessionStorage.clear()
                window.location.href='/'
            }
            else {
                console.log(response.data)
                setTaskTimeData(response.data)
                setFilterTaskTimeData(response.data)
                setRows(response.totalRows)
                setDataLoader(true)
            }
        }).catch((errs) => {
            showAlert(errs.message, "warning")
        }).finally(() => { setLoading(false) })
    }

    useEffect(() => {
        getAllTheTimeofTask()
    }, [])

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                borderTop: "1px solid #80808021",
                borderLeft: "1px solid #80808021",
                borderRight: "1px solid #80808021",
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                borderBottom: "1px solid #80808021",
                borderLeft: "1px solid #80808021",
                borderRight: "1px solid #80808021",
            },
        },
    };

    const columns = [
        {
            name: "R.No",
            cell: (row, index) => index + 1,
            grow:0,
            minWidth: "50px",
        },
        {
            name: "Start Time",
            selector: (row) => 
            <div className='pacDetailsBox'>
                {row.time_start?
                <span className='mb-2 mt-2 package_name'>
                {row.time_start}</span>  :
                <span className="mb-2 mt-2 notFoundMsg">Not Found</span>}
            </div>
        },
        {
            name: "End Time",
            selector: (row) => 
            <div className='pacDetailsBox'>
                {row.time_end?
                <span className='mb-2 mt-2 package_name'>
                {row.time_end}</span>  :
                <span className="mb-2 mt-2 notFoundMsg">Not Found</span>}
            </div>
        },
        {
            name: "Today Date",
            selector: (row) =>
            <div className=''>
                {row.today_date?
                 <span>{row.today_date.slice(0,10)}</span>  :
                <span className="notFoundMsg">Not Found</span>}
            </div>
        },
        {
            name : "Actions",
            grow: 0,
            minWidth: "120px",
            cell : (row) => 
            <div className='dataTableIconBox'>
                <button type="submit" className='updateAlert'
                    onClick={(e)=> {
                    setTaskIds({
                        task_id: row.task_id,
                        task_time_id : row.id
                    })
                    setStartTime(isTaskTimeData.filter(data => data.task_id == row.task_id)[0].time_start)
                    setEndTime(isTaskTimeData.filter(data => data.task_id == row.task_id)[0].time_end)
                    setTodayDate(isTaskTimeData.filter(data => data.task_id == row.task_id)[0].today_date)
                    setGetTimeModal(!isGetTimeModal)
                }}
                >
                    <Update_ico />
                </button>
            </div>
        }
    ]

    const handlePageChange = page => {
        setPage(parseInt(page) - 1)
    }
    useEffect(() => {
        getAllTheTimeofTask(page)
    }, [page])

    const OrderSearchFilter = (e) => {
        if (e.target.value == ' '){
            setTaskTimeData(isFilterTaskTimeData)
        }else{
            setLoading(true)
            setDataLoader(false)
            setTimeout(() => {
                const filterResult = isFilterTaskTimeData.filter(item => 
                item.time_end.toLowerCase().includes(e.target.value.toLowerCase())
                || item.time_start.toLowerCase().includes(e.target.value.toLowerCase())
                || item.today_date.toLowerCase().includes(e.target.value.toLowerCase()))
                setTaskTimeData(filterResult)
                setLoading(false)
                setDataLoader(true)
            }, 2000);
        }
        setFilterValue(e.target.value)
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet (isTaskTimeData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer= XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs (data, "data" + fileExtension);
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle:"ORDER REQUEST LIST",
      pageStyle:"print",
    });

    const handlehideShow=()=>{
        document.getElementById('hidediv').style.display='block'
        setTimeout(() => {
            document.getElementById('hidediv').style.display='none'
        }, 1);
    }


    const updateTaskSentApproval = async () => {
        setbtnLoading(true);
        setBtnEnaledAndDisabled(true);
        try {
            await fetch(`${config['baseUrl']}/timeTask/UpdateTaskTime`, {
              method: "POST",
              headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` },
              body: JSON.stringify({
                "task_time_id": isTaskIds.task_time_id,
                "task_id": isTaskIds.task_id,
                "time_start": isStartTime,
                "time_end": isEndTime,
                "today_date": isTodayDate
              })
            }).then((response) => {
                return response.json()
            }).then((response) => {
                if (response.messsage == "unauthorized") {
                    fetch(`${config['baseUrl']}/timeTask/UpdateTaskTime`, {
                        method: "POST",
                        headers: { "content-type": "application/json", "refereshToken": `Bareer ${get_refresh_token}` },
                        body: JSON.stringify({
                            "task_time_id": isTaskIds.task_time_id,
                            "task_id": isTaskIds.task_id,
                            "time_start": isStartTime,
                            "time_end": isEndTime,
                            "today_date": isTodayDate
                          })
                    }).then(response => {
                        return response.json()
                    }).then(response => {
                        secureLocalStorage.setItem("refresh", response.referesh_token);
                        secureLocalStorage.setItem("access_token", response.access_token);
                        setbtnLoading(false);
                        setBtnEnaledAndDisabled(false);
                        showAlert(response.message,"success")
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    }).catch((errs) => {
                        setbtnLoading(false);
                        setBtnEnaledAndDisabled(false);
                        showAlert(errs.message,"warning")
                    })
                }
                else if(response.messsage == "timeout error"){
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.href='/'
                }
                else {
                    if(response.failed){
                        setbtnLoading(false);
                        setBtnEnaledAndDisabled(false);
                        showAlert(response.message,"warning")
                    }else{
                        setbtnLoading(false);
                        setBtnEnaledAndDisabled(false);
                        showAlert(response.message,"success")
                        setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                    }
                }
            })
        } catch (error) { 
            setbtnLoading(false);
            setBtnEnaledAndDisabled(false);
            showAlert(error.message,"warning")
        }
    }



    return (
        <>
            <div className="webQuotBox">
                <h4 className='webQuotBoxOne'>Check Task Time</h4>
                <div className="innerWebQuotBox">
                    <div className="btnBox">
                        <button onClick={setCopied} className='copyBelow'><Copy_ico /></button>
                        <button className='makeExcelFile'onClick={(e)=> {exportToExcel()}}><Excel_ico /></button>
                        <button className='PrintFile' onClick={()=>{
                            handlehideShow()
                            handlePrint()
                        }}><Print_ico /></button>
                        <Tooltip
                            anchorSelect=".copyBelow"
                            place="bottom"
                            content="Copy Below Data"
                            style={{zIndex: "9999"}}
                        />
                        <Tooltip
                            anchorSelect=".makeExcelFile"
                            place="bottom"
                            content="Make Excel File of below Data"
                            style={{zIndex: "9999"}}
                        />
                        <Tooltip
                            anchorSelect=".makePDFFile"
                            place="bottom"
                            content="Make PDF File of below Data"
                            style={{zIndex: "9999"}}
                        />
                        <Tooltip
                            anchorSelect=".PrintFile"
                            place="bottom"
                            content="Print out of below data"
                            style={{zIndex: "9999"}}
                        />
                    </div>
                    <h4 className='webQuotBoxTwo'>Add Task</h4>
                    <SearchBar 
                        {...{OrderSearchFilter,isFilterValue}}
                    />
                </div>
                <ul>
                    {error && (
                        <li className={`alert alert-${error.type}` + " " + "mt-4"}>{`${error.message}`}</li>
                    )}
                </ul>
                {loading && (
                    <div className="loaderBox">
                        <div className="loader">
                            <div className="one"></div>
                            <div className="two"></div>
                            <div className="three"></div>
                            <div className="four"></div>
                        </div>
                    </div>
                )}
                {dataLoader && (
                    <OrderDataTable
                        columns={columns}
                        data={isTaskTimeData}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={Isrows}
                        paginationComponentOptions={{
                            noRowsPerPage: true,
                        }}
                        customStyles={customStyles}
                        onChangePage={handlePageChange}
                    />
                )}
                
                {dataLoader && (
                        <div ref={componentRef}  id='hidediv' style={{display: "none"}}>
                            <table style={{width: "100%"}}>
                            <thead className='printTableHead'>
                                <tr>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Today Date</th>
                                </tr>
                            </thead>
                            <tbody className='printTableBody'>
                                {isTaskTimeData?.map((items)=> {
                                    return(
                                        <tr key={items?.id}>
                                            <td>
                                               <span> {items?.time_start ? items?.time_start : "Not Found"}</span>
                                            </td>
                                            <td>
                                               <span> {items?.time_end ? items?.time_end : "Not Found"}</span>
                                            </td>
                                            <td>
                                               <span> {items?.today_date ? items?.today_date: "Not Found"}</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                            </table>
                        </div>
                )}
            </div>
            
            {isGetTimeModal && (
                <>
                    <div className="deleteAlertBox">
                   
                        <div className="deleteAlertBoxInner">
                            <Cross_ico onClick={() => { setGetTimeModal(false) }} className='closeIco' />
                            <ul>
                            {error && (
                                <li className={`alert alert-${error.type}` + " " + "mt-4"}>{`${error.message}`}</li>
                            )}
                            </ul>
                            <div className="form-group">
                                <label className="text-light">Enter a start time</label>
                                <input type="datetime-local" className='form-control' placeholder='Enter a start time'
                                  defaultValue={isStartTime}  onChange={(e) => {setStartTime(e.target.value)}}
                                />
                            </div>
                            <div className='form-group'>
                                <label className="text-light">Enter a end time</label>
                                <input type="datetime-local" className='form-control' placeholder='Enter a end time'
                                  defaultValue={isEndTime}  onChange={(e) => {setEndTime(e.target.value)}}
                                />
                            </div>
                            <div className="deleteBtnBox">
                                <button  onClick={updateTaskSentApproval} disabled={btnEnaledAndDisabled}>{btnloading ? "A moment please..." : "Update Time"}</button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default ViewTaskTimeCom