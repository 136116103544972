import React, { useEffect, useRef, useState } from 'react'
import './assets/css/dataReport.css'
import secureLocalStorage from "react-secure-storage";
import DataTable from 'react-data-table-component'
import { FiCopy as Copy_ico } from "react-icons/fi";
import { RiFileExcel2Fill as Excel_ico } from "react-icons/ri";
import useClipboard from "react-use-clipboard";
import { FiPrinter as Print_ico } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import SearchBar from './SearchBar';
import { useReactToPrint } from 'react-to-print';
import { RxCross2 as Cross_ico } from "react-icons/rx";
import { CgDanger as Danger_ico } from "react-icons/cg";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { GrUpdate } from "react-icons/gr";
import { MdOutlinePending } from "react-icons/md";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { MdSystemUpdateAlt } from "react-icons/md";
import { message } from 'antd';
const config = require('../components/config.json')


function DataReportCom() {
    const [isFile, setFile] = useState('');
    var get_refresh_token = secureLocalStorage.getItem("refresh");
    var get_access_token = secureLocalStorage.getItem("access_token");
    const [loading, setLoading] = useState(true);
    const [dataLoader, setDataLoader] = useState(false);
    const [page, setPage] = useState(parseInt(0))
    const [Isrows, setRows] = useState([]);
    const [isData, setData] = useState([])
    const [isFileModal, setFileModal] = useState(false)
    const [isStatusModal, setStatusModal] = useState(false)
    const [isFilterValue, setFilterValue] = useState(null);
    const [isFilterData, setFilterData] = useState([])
    const [isStatus, setStatus] = useState(null)
    const [isId, setId] = useState(null)
    const [isDeleteAlertShow, setDeleteAlertShow] = useState(false);
    const filterOption = true;
    const [isCheckbox, setCheckbox] = useState(2)
    const [messageApi, contextHolder] = message.useMessage();
    const [currentDate, setCurrentDate] = useState(new Date());
    const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
    const [isDate, setDate] = useState([
        { FromDate: null },
        { ToDate: null },
    ]);
    const [isTableError, setTableError] = useState({
        message: "",
        color: "",
    })
    const [error, setError,] = useState({
        message: "",
        color: "",
        loading: "",
    });

    const formData = new FormData();
    if (isFile !== '') {
        console.log(formData,'From')
        formData.append("excelFile", isFile);
    }
    const SubmitFile = async (e) => {
        console.log(formData,'formData')
        e.preventDefault(e);
        setError({
            message: "",
            color: "",
            loading: true
        })
        try {
            await fetch(`${config['baseUrl']}/excelupload`, {
                method: "POST",
                headers: { 
                "Accept": "form-data",
                "accessToken": `Bareer ${get_access_token}`
             },
                body: formData
            }).then((response) => {
                return response.json()
            }).then((response) => {
                if (response.messsage == "unauthorized") {
                    fetch(`${config['baseUrl']}/excelupload`, {
                        method: "POST",
                        headers: { "Accept": "form-data", 
                        "refereshToken": `Bareer ${get_refresh_token}`
                     },
                        body: formData
                    }).then(response => {
                        return response.json()
                    }).then(response => {
                        secureLocalStorage.setItem("refresh", response.referesh_token);
                        secureLocalStorage.setItem("access_token", response.access_token);
                        if (response.success) {
                            setError({
                                message: response.message,
                                color: "success",
                                loading: false
                            })
                            setTimeout(() => {
                                // window.location.reload();
                            }, 1000);
                        } else {
                            setError({
                                message: response.message,
                                color: "warning",
                                loading: false
                            })
                        }
                    }).catch((errs) => {
                        setError({
                            message: errs.message,
                            color: "warning",
                            loading: false
                        })
                    })
                }
                else if (response.messsage == "timeout error") {
                }
                else {
                    if (response.success) {
                        setError({
                            message: response.message,
                            color: "success",
                            loading: false
                        })
                        setTimeout(() => {
                            // window.location.reload();
                        }, 1000);
                    } else {
                        setError({
                            message: response.message,
                            color: "warning",
                            loading: false
                        })
                        console.log("response", response)
                    }
                }
            })
        } catch (error) {
            setError({
                message: error.message,
                color: "warning",
                loading: false
            })
        }
    }
    const SubmitStatus = async (e) => {
        e.preventDefault(e);
        setError({
            message: "",
            color: "",
            loading: true
        })
        try {
            await fetch(`${config['baseUrl']}/updateExcelData`, {
                method: "POST",
                headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` },
                body: JSON.stringify({
                    "id": isId,
                    "status": isStatus
                })
            }).then((response) => {
                return response.json()
            }).then((response) => {
                if (response.messsage == "unauthorized") {
                    fetch(`${config['baseUrl']}/updateExcelData`, {
                        method: "POST",
                        headers: { "content-type": "application/json", "accessToken": `Bareer ${get_refresh_token}` },
                        body: JSON.stringify({
                            "id": isId,
                            "status": isStatus
                        })
                    }).then(response => {
                        return response.json()
                    }).then(response => {
                        // secureLocalStorage.setItem("refresh", response.referesh_token);
                        // secureLocalStorage.setItem("access_token", response.access_token);
                        if (response.success) {
                            setError({
                                message: response.message,
                                color: "success",
                                loading: false
                            })
                            setTimeout(() => {
                                // window.location.reload();
                            }, 1000);
                        } else {
                            setError({
                                message: response.message,
                                color: "warning",
                                loading: false
                            })
                        }
                    }).catch((errs) => {
                        setError({
                            message: errs.message,
                            color: "warning",
                            loading: false
                        })
                    })
                }
                else if (response.messsage == "timeout error") {
                }
                else {
                    if (response.success) {
                        setError({
                            message: response.message,
                            color: "success",
                            loading: false
                        })
                        setTimeout(() => {
                            // window.location.reload();
                        }, 1000);
                    } else {
                        setError({
                            message: response.message,
                            color: "warning",
                            loading: false
                        })
                    }
                }
            })
        } catch (error) {
            setError({
                message: error.message,
                color: "warning",
                loading: false
            })
        }
    }
    const DeleteRecord = async (e) => {
        e.preventDefault(e);
        setError({
            message: "",
            color: "",
            loading: true
        })
        try {
            await fetch(`${config['baseUrl']}/deleteExcelData`, {
                method: "POST",
                headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` },
                body: JSON.stringify({
                    "id": isId,
                })
            }).then((response) => {
                return response.json()
            }).then((response) => {
                if (response.messsage == "unauthorized") {
                    fetch(`${config['baseUrl']}/deleteExcelData`, {
                        method: "POST",
                        headers: { "content-type": "application/json", "accessToken": `Bareer ${get_refresh_token}` },
                        body: JSON.stringify({
                            "id": isId,
                        })
                    }).then(response => {
                        return response.json()
                    }).then(response => {
                        // secureLocalStorage.setItem("refresh", response.referesh_token);
                        // secureLocalStorage.setItem("access_token", response.access_token);
                        if (response.success) {
                            setError({
                                message: "You have been deleted successfully",
                                color: "success",
                                loading: false
                            })
                            setTimeout(() => {
                                // window.location.reload();
                            }, 1000);
                        } else {
                            setError({
                                message: response.message,
                                color: "warning",
                                loading: false
                            })
                        }
                    }).catch((errs) => {
                        setError({
                            message: errs.message,
                            color: "warning",
                            loading: false
                        })
                    })
                }
                else if (response.messsage == "timeout error") {
                    // localStorage.clear()
                    // sessionStorage.clear()
                    // window.location.href = '/'
                }
                else {
                    if (response.success) {
                        setError({
                            message: "You have been deleted successfully",
                            color: "success",
                            loading: false
                        })
                        setTimeout(() => {
                            // window.location.reload();
                        }, 1000);
                    } else {
                        setError({
                            message: response.message,
                            color: "warning",
                            loading: false
                        })
                    }
                }
            })
        } catch (error) {
            setError({
                message: error.message,
                color: "warning",
                loading: false
            })
        }
    }
    async function GetData(body) {
        setLoading(true)
        setDataLoader(false)
        await fetch(`${config['baseUrl']}/getexcelData/${body?.pageNo}/${body?.search}/${body?.status}/${body?.startDate}/${body?.endDate}`, {
            method: "GET",
            headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` }
        }).then((response) => {
            return response.json()
        }).then(async (response) => {
            if (response.messsage == "unauthorized") {
                await fetch(`${config['baseUrl']}/getexcelData/${body?.pageNo}/${body?.search}/${body?.status}/${body?.startDate}/${body?.endDate}`, {
                    method: "GET",
                    headers: { "content-type": "application/json", "refereshToken": `Bareer ${get_refresh_token}` }
                }).then(response => {
                    return response.json()
                }).then(response => {
                    secureLocalStorage.setItem("refresh", response.referesh_token);
                    secureLocalStorage.setItem("access_token", response.access_token);
                    if (response?.success) {
                        setData(response?.data?.[0])
                        setRows(response?.totalRows?.[0])
                        setDataLoader(true)
                    } else {
                        setTableError({
                            message: response.message,
                            color: "waring"
                        })
                    }

                }).catch((errs) => {
                    setTableError({
                        message: errs.message,
                        color: "waring"
                    })
                }).finally(() => { setLoading(false) })
            }
            else if (response.messsage == "timeout error") {
            
            }
            else {
                if (response?.success) {
                    setData(response?.data?.[0])
                    setRows(response?.totalRows?.[0])
                    console.log(response?.data?.[0], "daatr")

                    setDataLoader(true)
                } else {
                    setTableError({
                        message: response.message,
                        color: "waring"
                    })
                }
            }
        }).catch((errs) => {
            setTableError({
                message: errs.message,
                color: "waring"
            })
        }).finally(() => { setLoading(false) })
    }


    useEffect(() => {
        if (isFilterValue == null || isFilterValue == '') {
            GetData({
                pageNo: page == 0 ? 1 : page,
                search: null,
                status: isCheckbox === 2 ? null : isCheckbox,
                startDate: isDate?.[0]?.FromDate == null ? null : isDate?.[0]?.FromDate,
                endDate: isDate?.[0]?.FromDate !== null ? isDate?.[1]?.ToDate == null ? formattedDate : isDate?.[1]?.ToDate : isDate?.[1]?.ToDate
            })
            console.log("first page")
        } else {
            GetData({
                pageNo: page == 0 ? 1 : page,
                search: isFilterValue,
                status: isCheckbox === 2 ? null : isCheckbox,
                startDate: isDate?.[0]?.FromDate == null ? null : isDate?.[0]?.FromDate,
                endDate: isDate?.[0]?.FromDate !== null ? isDate?.[1]?.ToDate == null ? formattedDate : isDate?.[1]?.ToDate : isDate?.[1]?.ToDate
            })
            console.log("second page")
        }
    }, [page, isFilterValue, isCheckbox, isDate])


    const handlePageChange = page => {
        setPage(parseInt(page) - 1)
    }
    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                borderTop: "1px solid #80808021",
                borderLeft: "1px solid #80808021",
                borderRight: "1px solid #80808021",
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                borderBottom: "1px solid #80808021",
                borderLeft: "1px solid #80808021",
                borderRight: "1px solid #80808021",
            },
        },
    };
    const columns = [
        {
            name: "R.No",
            cell: (row, index) => index + 1,
            grow: 0,
            minWidth: "50px",
        },
        {
            name: "Registrant Name",
            grow: 0,
            minWidth: "400px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.registrant_name ? row.registrant_name : 'Not found'}</span>
                </div>
        },
        {
            name: "Registrant City Name",
            grow: 0,
            minWidth: "400px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.registrant_city ? row.registrant_city : 'Not found'}</span>
                </div>
        },
        {
            name: "Registrant State Name",
            grow: 0,
            minWidth: "400px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.registrant_state ? row.registrant_state : 'Not found'}</span>
                </div>
        },
        {
            name: "Registrant Domain Name",
            grow: 0,
            minWidth: "400px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.domain_registrar_name ? row.domain_registrar_name : 'Not found'}</span>
                </div>
        },
        {
            name: "Domain Name",
            grow: 0,
            minWidth: "400px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.domain_name ? row.domain_name : 'Not found'}</span>
                </div>
        },
        {
            name: "Email",
            grow: 0,
            minWidth: "400px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.registrant_email ? row.registrant_email : 'Not found'}</span>
                </div>
        },
        {
            name: "Registrant Phone",
            grow: 0,
            minWidth: "200px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.registrant_phone ? row.registrant_phone : 'Not found'}</span>
                </div>
        },
        {
            name: "Created at",
            grow: 0,
            minWidth: "200px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.created_at ? row.created_at.slice(0, 10) : 'Not found'}</span>
                    <br />
                    <span>{row?.created_at ? row.created_at.slice(10, 25) : 'Not found'}</span>
                </div>
        },
        {
            name: "Status",
            grow: 0,
            minWidth: "100px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <span>{row?.status == 0 ? <MdOutlinePending className='Pendingicon' /> : row?.status == 1 ? <IoCheckmarkDoneCircleSharp className='DoneIcon' /> : "Not Found"}</span>
                </div>
        },

        {
            name: "Actions",
            grow: 0,
            minWidth: "200px",
            selector: (row) =>
                <div className='dataTableIconBox'>
                    <button className='status' disabled={row?.status == "1" ? true : false} onClick={() => UpdateCall(row?.id, row?.status)}><GrUpdate style={{ color: row?.status === "1" ? 'lightblue' : 'blue' }} /></button>
                    {/* {
                        secureLocalStorage.getItem("role_id") == 1 ?
                        <button className='delete' onClick={() => {
                            setId(row.id);
                            setDeleteAlertShow(true)
                        }}><MdDelete style={{ color: 'red' }} /></button> : null
                    } */}
                    {/* <Tooltip
                        anchorSelect=".status"
                        place="bottom"
                        content="update status"
                    />
                    <Tooltip
                        anchorSelect=".delete"
                        place="bottom"
                        content="delete this row"
                    /> */}
                </div>

        }
    ]

    if (secureLocalStorage.getItem("role_id") == 1) {
        columns.push({
            name: "Call By",
            grow: 0,
            minWidth: "100px",
            left: true,
            selector: (row) =>
                <div className=''>
                    <>
                     <span>{
                      secureLocalStorage.getItem("role_id") == 1 ?
                      row?.called_by
                      : "Not Available"}</span>

                    </>
                </div>
        })
    }
    const [isCopied, setCopied] = useClipboard(JSON.stringify(isData));
    const OrderSearchFilter = (e) => {
        if (e.target.value == ' ') {
            setFilterValue(null)
        } else {
            setFilterValue(e.target.value)
        }

    }

    const exceltem = [
        {
            name: "",
            age: "",
            color: "",
        }]

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(exceltem);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "data" + fileExtension);
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Excel Report Data",
        pageStyle: "print",
    });
    const handlehideShow = () => {
        document.getElementById('hidediv').style.display = 'block'
        setTimeout(() => {
            document.getElementById('hidediv').style.display = 'none'
        }, 1);
    }

    const onChange = (e, prams) => {
        if (prams == "checkBox") {
            setCheckbox(e.target.value)
        } else if (prams == "start") {
            setDate(prevData => {
                const newDate = [...prevData];
                newDate[0].FromDate = e.target.value;
                return newDate;
            });
        } else if (prams == "end") {
            setDate(prevData => {
                const newDate = [...prevData];
                newDate[1].ToDate = e.target.value;
                return newDate;
            });
        }
    }
    const onClick = () => {
        setCheckbox(2)
        setFilterValue(null)
        setDate(prevData => {
            const newDate = [...prevData];
            newDate[0].FromDate = null;
            newDate[1].ToDate = null;
            return newDate;
        });
    }


    async function UpdateCall(id, status, body) {
        console.log(id, status)
        await fetch(`${config['baseUrl']}/updateCallStatus`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accessToken: `Bareer ${get_access_token}`,
                },
                body: JSON.stringify({
                    "status": status,
                    "id": id,
                }),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then(async (response) => {
                GetData({
                    pageNo: page == 0 ? 1 : page,
                    search: null,
                    status: isCheckbox === 2 ? null : isCheckbox,
                    startDate: isDate?.[0]?.FromDate == null ? null : isDate?.[0]?.FromDate,
                    endDate: isDate?.[0]?.FromDate !== null ? isDate?.[1]?.ToDate == null ? formattedDate : isDate?.[1]?.ToDate : isDate?.[1]?.ToDate
                })
                if (response.success) {
                    messageApi.open({
                        type: "success",
                        content: "You have successfully Updated Call",
                    });                
                } else {
                    messageApi.open({
                        type: "error",
                        content: response?.message || response?.messsage,
                    });
                }
            })
            .catch((error) => {
                messageApi.open({
                    type: "error",
                    content: error?.message || error?.messsage,
                });
            });
    }



    return (
        <>
            {contextHolder}
            <div className="orderBox">
                <h4 className='orderBoxHeadOne'>Excel Report</h4>
                <div className="innerOrderBox">
                    <div className="btnBox">
                        {
                            secureLocalStorage.getItem("role_id") == 1 ?
                                <>
                                    <button onClick={() => { setFileModal(!isFileModal) }}>Upload File</button>
                                    <button onClick={exportToExcel}>Download Format</button>
                                </>
                                : null

                        }
                        {/* <button onClick={setCopied} className='copyBelow'><Copy_ico /></button>
                        <button className='makeExcelFile' onClick={(e) => { exportToExcel() }}><Excel_ico /></button>
                        <button className='PrintFile' onClick={() => {
                            handlehideShow()
                            handlePrint()
                        }}><Print_ico /></button>
                        <Tooltip
                            anchorSelect=".copyBelow"
                            place="bottom"
                            content="Copy Below Data"
                            style={{ zIndex: "9999" }}
                        />
                        <Tooltip
                            anchorSelect=".makeExcelFile"
                            place="bottom"
                            content="Make Excel File of below Data"
                            style={{ zIndex: "9999" }}
                        />
                        <Tooltip
                            anchorSelect=".makePDFFile"
                            place="bottom"
                            content="Make PDF File of below Data"
                            style={{ zIndex: "9999" }}
                        />
                        <Tooltip
                            anchorSelect=".PrintFile"
                            place="bottom"
                            content="Print out of below data"
                            style={{ zIndex: "9999" }}
                        /> */}
                    </div>
                    <h4 className='orderBoxHeadTwo'>Excel Report</h4>
                    <SearchBar
                        {...{ OrderSearchFilter, isFilterValue, filterOption, onChange, isCheckbox, isDate, onClick }}
                    />
                </div>
                {loading && (
                    <div className="loaderBox">
                        <div className="loader">
                            <div className="one"></div>
                            <div className="two"></div>
                            <div className="three"></div>
                            <div className="four"></div>
                        </div>
                    </div>
                )}
                {dataLoader && (
                    <DataTable
                        columns={columns}
                        data={isData}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={Isrows}
                        paginationComponentOptions={{
                            noRowsPerPage: true,
                        }}
                        customStyles={customStyles}
                        onChangePage={handlePageChange}
                    />

                )}
                {dataLoader && (
                    <div ref={componentRef} id='hidediv' style={{ display: "none" }}>
                        <table style={{ width: "100%", padding: "50px" }}>
                            <thead className='printTableHead'>
                                <tr>
                                    <th>City Name</th>
                                    <th>State Name</th>
                                    <th>Domain Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody className='printTableBody'>
                                {isData?.map((items) => {
                                    return (
                                        <tr key={items?.id}>
                                            <td style={{ display: "flex", flexDirection: "column" }}>
                                                <span> {items?.registrant_city ? items?.registrant_city : "Not Found"}</span>
                                                <span>{items?.registrant_state ? items?.registrant_state : "Not Found"}</span>
                                            </td>
                                            <td>
                                                <span>{items?.domain_name ? items?.domain_name : 'Not Found'}</span>
                                            </td>
                                            <td style={{ display: "flex", flexDirection: "column" }}>
                                                <span> {items?.registrant_email ? items?.registrant_email : "Not Found"}</span>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                )}
            </div>


            {isFileModal && (
                <div className="modalFileExcelBox">
                    <div className='dataReportFileBox'>
                        <ul>
                            {error && (
                                <li className={`alert alert-${error.color}` + " " + "mt-4"}>{`${error.message}`}</li>
                            )}
                        </ul>
                        <form onSubmit={SubmitFile}>
                            <RxCross2 onClick={() => {
                                setFileModal(false)
                                setError({
                                    message: "",
                                    color: "",
                                    loading: false
                                })
                            }} />
                            <h5>Upload Excel File</h5>
                            <input type="file" accept=".xls, .xlsx"
                                onChange={(e) => { setFile(e.target.files[0]) }}
                            />
                            <button type='submit'>{error?.loading == true ? "Please Wait..." : "Submit"}</button>
                        </form>
                    </div>
                </div>
            )}

            {isStatusModal && (
                <div className="modalFileExcelBox">
                    <div className='dataReportFileBox'>
                        <ul>
                            {error && (
                                <li className={`alert alert-${error.color}` + " " + "mt-4"}>{`${error.message}`}</li>
                            )}
                        </ul>
                        <form onSubmit={SubmitStatus}>
                            <RxCross2 onClick={() => {
                                setId(null)
                                setStatusModal(false)
                                setError({
                                    message: "",
                                    color: "",
                                    loading: false
                                })
                            }} />
                            <h5>Update Status</h5>
                            <select className="form-control" style={{ width: "95%" }} onChange={(e) => { setStatus(e.target.value) }}>
                                <option selected disabled value="">-- Select One --</option>
                                <option selected={isData.filter(data => data.id === isId)?.[0].status == 1 ? true : false} value="1">Done</option>
                                <option selected={isData.filter(data => data.id === isId)?.[0].status == 0 ? true : false}>Pending</option>
                            </select>
                            <button type='submit'>{error?.loading == true ? "Please Wait..." : "Submit"}</button>
                        </form>
                    </div>
                </div>
            )}

            {isDeleteAlertShow && (
                <>
                    <div className="deleteAlertBox">
                        <div className="deleteAlertBoxInner">
                            <Cross_ico onClick={() => {
                                setDeleteAlertShow(false)
                                setId(null)
                                setError({
                                    message: "",
                                    color: "",
                                    loading: false
                                })
                            }} className='closeIco' />
                            {error && (
                                <ul>
                                    <li className={`alert alert-${error?.color}` + " " + "m-3"}>{error?.message}</li>
                                </ul>
                            )}
                            <div className="deleteAlertIconBox">
                                <Danger_ico />
                            </div>
                            <span>are you sure, you want to delete this row.</span>
                            <div className="deleteBtnBox">
                                <button onClick={DeleteRecord}>{error?.loading == true ? "Please Wait..." : "Submit"}</button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default DataReportCom
