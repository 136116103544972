import React, { useState } from 'react'
import { AiOutlineSearch as Search_ico } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import '../components/assets/css/search.css'


function SearchBar(props) {
  const [isFilterBox, setFilterBox] = useState(false)
  const [isData, setData] = useState([
    { name: 'All', value: "2" },
    { name: 'Done', value: "1" },
    { name: 'Pending', value: "0" },
  ])


  return (
    <>
      <div className='searchBar'>
        <Search_ico />
        <input type="search" className="search" placeholder='Something search here'
          value={props?.isFilterValue}
          onChange={props.OrderSearchFilter}
        />
        {
          props?.filterOption == true ?
            <div>
              <FaFilter className='filterIcon' onClick={() => { setFilterBox(!isFilterBox) }} />
              {isFilterBox == true ? (
                <div className='filterMainBox'>
                  <h6>Filter With Checked</h6>
                  <div className='filterCheckBox'>

                    {
                      isData?.map((items) => {
                        return <>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox"
                              checked={props?.isCheckbox == items?.value ? true : ""}
                              onChange={(e) => props?.onChange(e,"checkBox")} value={items?.value}
                              id={items?.name}
                            />
                            <label class="form-check-label" htmlFor={items?.name} >{items?.name}</label>
                          </div>
                        </>
                      })
                    }
                  </div>
                  <h6 className='mt-3'>Filter With Date</h6>
                  <div className='filterDatesInputBox'>
                    <div class="form-group">
                      <label for="startDate">Start Date</label>
                      <input type="date" value={props?.isDate[0]?.FromDate} onChange={(e) => props?.onChange(e,"start")} class="form-control" id="startDate" placeholder="Start Date" />
                    </div>
                    <div class="form-group">
                      <label for="endDate">End Date</label>
                      <input type="date" value={props?.isDate[0]?.ToDate} onChange={(e) => props?.onChange(e,"end")} class="form-control" id="startDate" placeholder="End Date" />
                    </div>
                  </div>
                  <button onClick={() => {props?.onClick()}}>Clear</button>
                </div>
              ) : null
              }

            </div>
            : null
        }
      </div>
    </>
  )
}

export default SearchBar