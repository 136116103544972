import React, { useEffect, useState } from 'react'
import SideBar from '../../components/SideBar';
import TopBar from '../../components/topBar';
import Board from "./dnd/board/Board";
import style from './addtask.module.css'
import { Modal, message, Button, Upload, Spin } from 'antd';
import { createTaskSchema } from './schema';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput, TextArea, FormSelect } from '../component/formInput';
import * as AllTask_Action from "../../store/actions/Task/index";
import { connect } from "react-redux";
import { UploadOutlined } from '@ant-design/icons';

function AddTask({ GetAllProjects, GetAllUsers, GetAllPriorties, CreateTask, GetAllTasks, TaskReducer }) {
    const allTasks = TaskReducer?.data
    const [allCompany, setAllCompany] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [allPriorties, setAllPriorties] = useState([])
    const [loading, setLoading] = useState(false)



    const [allFiles, setAllFiles] = useState([])
    const formData = new FormData();
    const userId = localStorage.getItem('user_id')

    useEffect(() => {
        getNecessaryData()
    }, [])

    const getNecessaryData = async () => {
        const GetAllTaskss = await GetAllTasks()
        const GetAllProject = await GetAllProjects()
        const GetAllUsersS = await GetAllUsers()
        const GetAllPriortiesD = await GetAllPriorties()
        setAllCompany(GetAllProject?.data)
        setAllUsers(GetAllUsersS?.data)
        setAllPriorties(GetAllPriortiesD?.data)
    }

    const [isMenuOpen, setMenuOpen] = useState(false)
    const hideShowMenuClick = () => {
        setMenuOpen(current => !current)
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        reset({
            title: "",
            summary: "",
            assignee: "",
            estimated_time: "",
            project_id: "",
            priority: "",
        })
    };

    const submitForm = async (data) => {
        try {
            setLoading(true)
            const isValid = await createTaskSchema.validate(data);
            if (isValid) {
                const response = await CreateTask(data)
                if (response?.success == "success") {
                    message.success('Successfully Created')
                    handleCancel(false)
                    GetAllTasks()
                    setLoading(false)
                    reset({
                        title: "",
                        summary: "",
                        assignee: "",
                        estimated_time: "",
                        project_id: "",
                        priority: "",
                    })
                }
                setLoading(false)
                reset({
                    title: "",
                    summary: "",
                    assignee: "",
                    estimated_time: "",
                    project_id: "",
                    priority: "",
                })
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
        }
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            title: "",
            summary: "",
            assignee: "",
            estimated_time: "",
            project_id: "",
            priority: "",
        },
        mode: "onChange",
        resolver: yupResolver(createTaskSchema),
    });


    return (
        <>
            <Modal footer={''} open={isModalOpen} onOk={submitForm} onCancel={handleCancel}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div>
                        <FormInput
                            label={'Title'}
                            placeholder={'Title'}
                            id="title"
                            name="title"
                            type="text"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />
                        <TextArea
                            label={'Summary'}
                            id="summary"
                            name="summary"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />
                        <FormSelect
                            options={allCompany}
                            label={'Project'}
                            id="project_id"
                            name="project_id"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />
                        <FormSelect
                            options={allUsers}
                            label={'Users'}
                            id="assignee"
                            name="assignee"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />
                        <FormSelect
                            options={allPriorties}
                            label={'Priorities'}
                            id="priority"
                            name="priority"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />
                        <FormInput
                            label={'Estimated Time'}
                            placeholder={''}
                            id="estimated_time"
                            name="estimated_time"
                            type="date"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />
                        <div className='d-flex align-items-center justify-content-end px-2'>
                            <button disabled={loading} className={style.taskAddBtn} type='submit'>{loading?<Spin/>:"Save"}</button>
                        </div>
                    </div>
                </form>
            </Modal>


            <div className="allPages">
                <SideBar
                    {...{ isMenuOpen, setMenuOpen }}
                />
                <div className="innerBox">
                    <TopBar
                        {...{ hideShowMenuClick }}
                    />
                    <div className="container interClass-menu">
                        <div className={`col-12 ${style.CreateTaskHeader}`}>
                            <h2>Task Management</h2>
                            {localStorage.getItem('user_role') == 3 ?
                                <button onClick={showModal}>Create Task</button>
                                : null}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Board initial={allTasks} withScrollableColumns />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


function mapStateToProps({ TaskReducer }) {
    return { TaskReducer };
}
export default connect(mapStateToProps, AllTask_Action)(AddTask);