import { message } from 'antd';
import baseUrl from '../../config.json'


export const getOrderList = (body) => async () => {
    try {       
        const response = await fetch(`${baseUrl?.baseUrl}/orders/GetOrders/${body?.page}/${body?.search}`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
           return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}


export const changeJobStatus = (body) => async () => {
    try {       
        const response = await fetch(`${baseUrl?.baseUrl}/orders/OrderCompleted`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "id": body?.id,
                "complete": body?.complete
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
           return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const getOrderFiles = (body) => async () => {
    try {       
        const response = await fetch(`${baseUrl?.baseUrl}/clients/getOrderfilesByClientId/${body?.id}`, {
            method: "Get",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
           return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}