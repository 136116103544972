import * as yup from "yup";


const createCompanySchema = yup.object().shape({
    name: yup.string().required("Company name is required"),
    email: yup.string().email().required("Email is required"),
    address: yup.string().required("Address is required"),
    number: yup.string().required("Phone number is required"),
});


export { createCompanySchema };