import { message } from 'antd';
import baseUrl from '../../config.json'
import {
    TASK_RELOAD_TRUE,
    TASK_START,
    TASK_COMPLETE,
    TASK_END
} from '../../actions/types'

const user_id = localStorage.getItem('user_id')

export const GetAllTasks = (body) => async (dispatch, getState) => {
    dispatch({
        type: TASK_START,
        payload: true,
    });
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/getTasksById/${user_id}`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        // if (res?.success == "success") 
        dispatch({
            type: TASK_COMPLETE,
            payload: res?.data,
        });
        // }
        // else {
        //     message.error('Somthing went wrong')
        //     dispatch({
        //         type: TASK_END,
        //         payload: false,
        //     });
        // }
    }
    catch (error) {
        console.log(error)
        dispatch({
            type: TASK_END,
            payload: false,
        });
    }
}

export const UploadTask = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/UploadTaskAttachments`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
            },
            body: body
        });
        const res = await response.json()
        return res
    }
    catch (error) {
        console.log(error)
    }
}

export const UpdateTasks = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/UpdateTaskStatus`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status_code: body?.status_code,
                assignee_id: body?.assignee_id,
                id: body?.id
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetAllProjects = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/projects/getAllProjects`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetAllUsers = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/users/GetAllUsers`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetAllPriorties = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/priority/GetAllPriorities`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const CreateTask = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/createTask`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                summary: body?.summary,
                assignee: body?.assignee,
                estimated_time: body?.estimated_time,
                project_id: body?.project_id,
                title: body?.title,
                priority: body?.priority,
                status_code: 1,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetTaskDetail = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/GetTaskDetailsById`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                task_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetAllComments = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/GetCommentsByTaskId`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lead_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const CreateCommentE = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/CreateComment`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lead_id: body.lead_id,
                comments: body.comments,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const CreateLikes = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/LikeComment`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                comment_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const CreateUnLikes = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/UnlikeComment`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                comment_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const UpdateComment = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/UnlikeComment`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                comment_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const SaveUpdateComment = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/UpdateComment`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                comment_id: body?.comment_id,
                comments: body?.comments,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const DeleteComment = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/DeleteComment`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                comment_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}


export const LoadComments = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/GetRepliesByCommentId`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                comment_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const ReplyComment = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/comments/CreateCommentReply`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lead_id: body?.lead_id,
                comments: body?.comments,
                parent_comment_id: body?.parent_comment_id,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetAllattachment = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/GetTaskAttachmentsByTaskId`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                taskId: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}


export const DeleteAttachment = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/DeleteTaskAttachment`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                attachment_id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const DeleteTask = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/DeleteTasks`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: body,
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const UpdateTask = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/tasks/UpdateTasks`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                summary: body?.summary,
                assignee: body?.assignee,
                estimated_time: body?.estimated_time,
                project_id: body?.project_id,
                title: body?.title,
                priority: body?.priority,
                id: body?.id
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const StartTaskTime = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/timeTask/CreateTimeTask`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                task_id: body?.task_id,
                from_destination: body?.from_destination,
                to_destination: body?.to_destination,
                moved_by_id: body?.moved_by_id
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const EndTaskTime = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/timeTask/EndTimeTask`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                task_id: body?.task_id,
                from_destination: body?.from_destination,
                to_destination: body?.to_destination,
                moved_by_id: body?.moved_by_id
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetUserById = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/users/GetUserById/${body}`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const GetTimeByID = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/timeTask/getTimeTaskById/${body}/0`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
    }
    catch (error) {
        console.log(error)
    }
}