import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PrimaryButton, CancelButton } from "../pages/component/Button"
import * as AllUpsale_Action from "../store/actions/AllUpsales/index";
import { MdDeleteOutline } from 'react-icons/md';
import './assets/css/AllFrontSales.css'
import { FaEdit } from 'react-icons/fa';
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { yupResolver } from "@hookform/resolvers/yup";
import { Space, Table, Tag, Tooltip } from "antd";
import * as yup from "yup";
import { Popconfirm } from 'antd';
import { message } from 'antd';
import ViewUpsale from "./modals/ViewUpsale";
// import baseUrl from './config.json'

const AllUpsales = ({ Red_AllUpsale, get_AllUpSale }) => {

    const [messageApi, contextHolder] = message.useMessage();
    var get_access_token = localStorage.getItem("access_token");
    const [isCode, setCode] = useState('')
    const [modal, setModal] = useState('read');
    const [isView, setView] = useState('read')
    const AddUpSale = (modal, isCode) => {
        setModal(modal)
        setCode(isCode)
    }
  


    useEffect(() => {
        get_AllUpSale()
    }, [])


    console.log(Red_AllUpsale ,'Red_AllUpsale')

    const columns = [
        {
            title: "Name",
            // dataIndex: "name",
            key: "name",
            render: (data) => (
                <div className="Tbtn" onClick={() => AddUpSale('modal', data?.id)}>{data?.name}</div>
            )
        },
        {
            title: "number",
            // dataIndex: "number",
            key: "number",
            render: (data) => (
                <div className="Tbtn" onClick={() => AddUpSale('modal', data?.id)}>{data?.number}</div>
            )
        },
        {
            title: "email",
            // dataIndex: "email",
            key: "email",
            render: (data) => (
                <div className="Tbtn" onClick={() => AddUpSale('modal', data?.id)}>{data?.email}</div>
            )
        },

        {
            title: "amount",
            // dataIndex: "amount",
            key: "amount",
            render: (data) => (
                <div className="Tbtn" onClick={() => AddUpSale('modal', data?.id)}>{data?.amount}</div>
            )
        },
        {
            title: "payment method",
            // dataIndex: "payment_method",
            key: "payment_method",
            render: (data) => (
                <div className="Tbtn" onClick={() => AddUpSale('modal', data?.id)}>{data?.payment_method}</div>
            )
        },
        {
            title: "Up sale",
            // dataIndex: "frontsale",
            key: "Sale",
            render: (data) => (
                <div className="Tbtn" onClick={() => AddUpSale('modal', data?.id)}>{data?.Sale ? data?.Sale : 'Not Found'}</div>
            )
        },


        {
            title: "status",
            // dataIndex: "status",
            key: "status",
            render: (data) => (
                <div className="Tbtn" onClick={() => AddUpSale('modal', data?.id)}>{data?.status}</div>
            )
        },

        // {
        //     title: "Update",
        //     key: "Update",
        //     render: (data) => (
        //         <Space size="middle">
        //             <button
        //                 // onClick={() => UpdateSale(data?.id)}
        //                 className="editBtn"
        //             >
        //                 <FaEdit />
        //             </button>

        //         </Space>
        //     ),
        // },
    ];


    // const UpdateSale = async (id) => {
    //     try {
    //         const response = await UpdateManualSale({
    //             id: id,
    //         });
    //         // console.log(response, 'response')

    //         if (response && response.success) {
    //             messageApi.success("Update Sale");
    //             setTimeout(() => {
    //                 cancel('read')
    //             }, 3000);
    //         } else {
    //             const errorMessage = response?.message || 'Failed to Update Sale';
    //             messageApi.error(errorMessage);
    //         }
    //     } catch (error) {
    //         console.error("Error occurred while Update Sale:", error);
    //         messageApi.error("An error occurred while Update Sale");
    //     }
    // };





    return (
        <>

            {contextHolder}
            <div className="container mt-2">
                <div className="row">

                    <div className="col-lg-12 maringClass">
                        <div className="d-flex justify-content-between align-item-center">
                            <h6>Up Sales</h6>
                            {/* <CancelButton onClick={EditBack} title={'Cancel'} /> */}
                            {/* <MdCancel onClick={EditBack} className="Icon" /> */}
                        </div>
                        <div>
                        </div>
                        <div>

                            <Table
                                columns={columns}
                                // loading={Red_AllUpsale?.loading}
                                dataSource={Red_AllUpsale?.AllUpsale?.[0]?.res?.data?.[0]}
                                scroll={{ x: 10 }}
                            // pagination={{
                            //     defaultCurrent: page,
                            //     total: Red_LeadMonthAndYear?.data?.[0]?.res?.data3,
                            //     onChange: (p) => {
                            //         setPage(p);
                            //     },
                            //     pageSize: pageSize,
                            // }}
                            />

                        </div>
                    </div>


                </div>
            </div>
            {modal == "modal" ? <ViewUpsale modal={modal} isCode={isCode} cancel={setModal} /> : ""}

        </>
    );
};
function mapStateToProps({ Red_AllUpsale }) {
    return { Red_AllUpsale };
}

export default connect(mapStateToProps, AllUpsale_Action)(AllUpsales);
