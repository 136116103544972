import {
    Get_Leads_Start,
    Get_Leads_Data,
    Get_Leads_End,
    Get_Project_Data,
    Get_Order_Data
} from '../types'
import { message } from 'antd';
import baseUrl from '../../config.json'




export const get_LeadMonthlyAndYearly_Data = (body) => async (dispatch) => {
    try {
        dispatch({
            type: Get_Leads_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/lead/GetCustomerLeadsByMonthAndYear`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "Month": body?.Month,
                "Year": body?.Year
            })
        });
        if (response.status === 200) {
            const res = await response.json()
            dispatch({
                type: Get_Leads_Data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_Leads_End,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_Leads_End,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}


export const get_ProjectByMonthYear_Data = (body) => async (dispatch) => {
    try {
        dispatch({
            type: Get_Leads_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/projects/GetProjectsByMonthAndYear`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "Month": body?.Month,
                "Year": body?.Year
            })
        });
        if (response.status === 200) {
            const res = await response.json()
            dispatch({
                type: Get_Project_Data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_Leads_End,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_Leads_End,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}



export const get_OrderByMonthYear_Data = (body) => async (dispatch) => {
    try {
        dispatch({
            type: Get_Leads_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/order/GetOrdersByMonthAndYear`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "Month": body?.Month,
                "Year": body?.Year
            })
        });
        if (response.status === 200) {
            const res = await response.json()
            
            dispatch({
                type: Get_Order_Data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_Leads_End,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_Leads_End,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}