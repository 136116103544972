import React, { useEffect, useState, useRef } from "react";
import Style from './schedule.module.css'
import SideBar from "../../components/SideBar";
import TopBar from "../../components/topBar";
import Footer from "../../components/Footer";
import SearchHeader from '../searchHeader/searchHeader'
import * as Schdule_Action from "../../store/actions/schedule/index";
import { FormInput, TextArea, FormSwitch, FormSelect } from '../component/formInput';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { scheduleSchema } from './scheduleSchema';
import { connect } from "react-redux";
import { Modal, Popconfirm, Select, Skeleton, Spin, message } from "antd";



const Schedule = ({ GetMonthData, GetEvents, CreateEvent, DeleteEventFunction, UpdateEventFunction }) => {
    const [selectedRange, setSelectedRange] = useState([]);
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [currentStripColor, setCurrentStripColor] = useState()
    const [calenderData, setCalenderData] = useState([])
    const [singleEvent, setSingleEvent] = useState();
    const [isMoreModal, setIsMoreModal] = useState(false);
    const [currnetDateEvent, setCurrnetDateEvent] = useState();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [allEvent, setAllEvent] = useState([])
    const [isDetail, setIsDetail] = useState('')
    const [isUpdate, setIsUpdate] = useState(false)
    const [currentMonth, setCurrentMonth] = useState(new Date()?.getMonth() + 1)
    const [currentYear, setCurrentYear] = useState(new Date()?.getFullYear())
    const [calenderLoader, setCalenderLoader] = useState(false)

    const hideShowMenuClick = () => {
        setMenuOpen(current => !current)
    }
    const LoadCalender = async (e) => {
        
        setCalenderLoader(true)
        const data = await GetMonthData(currentMonth)
        if (data?.success == "success") {
            setCalenderData(data?.data)
            setCalenderLoader(false)
        }
        const Event = await GetEvents()
        if (Event?.success == "success") {
            setAllEvent(Event?.data)
        }
    }
    useEffect(() => {
        LoadCalender(currentMonth)
    }, [])


    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (R) => {
        setIsModalOpen(true);
        setIsDetail(R)
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRange([])
        setIsMoreModal(false)
        reset({
            title: "",
            description: "",
            start_Time: "",
            end_Time: "",
            remind_Email: false,
        })
        setIsDetail('')
        setIsUpdate(false)
    };




    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const isSelecting = useRef(false);
    const [updateStartDate, setUpdateStartDate] = useState();
    const [updateEndDate, setUpdateEndDate] = useState();






    const [updateEventId, setUpdateEventId] = useState();

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const ensureColorDifference = (previousColor, newColor, threshold = 128) => {
        const getBrightness = (hexColor) => {
            const rgb = parseInt(hexColor?.slice(1), 16);
            const r = (rgb >> 16) & 0xff;
            const g = (rgb >> 8) & 0xff;
            const b = (rgb >> 0) & 0xff;
            return (r * 299 + g * 587 + b * 114) / 1000;
        };

        const brightnessDifference = Math.abs(getBrightness(previousColor) - getBrightness(newColor));
        return brightnessDifference >= threshold;
    };

    const handleMouseDown = (e, day, R) => {
        setStartDate(R.split(" ")[0])
        const handleGenerateColor = () => {
            let newColor = generateRandomColor();

            while (!ensureColorDifference(currentStripColor, newColor)) {
                newColor = generateRandomColor();
            }

            setCurrentStripColor(newColor);
        };
        handleGenerateColor()
        setIsMoreModal(true)
        generateRandomColor()
        e.preventDefault();
        isSelecting.current = true;
        setSelectedRange([Number(day)]);
    };
    const handleMouseEnter = (e, day, R) => {
        setEndDate(R.split(" ")[0])
        e.preventDefault();
        if (isSelecting.current) {
            setSelectedRange((prevRange) => {
                const [start] = prevRange;
                const newRange = Array.from(
                    { length: Math.abs(day - start) + 1 },
                    (_, index) => Math.min(start, day) + index
                );
                return newRange;
            });
        }
    };



    const submitForm = async (data) => {
        setSubmitLoading(true)
        try {
            const isValid = await scheduleSchema.validate(data);
            if (isValid) {
                if (isUpdate) {
                    const CreateTask = await UpdateEventFunction({
                        eventid: updateEventId,
                        title: data?.title,
                        description: data?.description,
                        start_date: `${currentYear}-${currentMonth}-${startDate.split("-")[2]} ${data?.start_Time}`,
                        end_date: `${currentYear}-${currentMonth}-${endDate.split("-")[2]} ${data?.end_Time}`,
                        remind_Email: data?.remind_Email,
                    })
                    if (CreateTask?.success == "success") {
                        handleCancel()
                        setSubmitLoading(false)
                        LoadCalender()
                        message.success('Schedule Updates')
                    }
                }
                else {
                    const CreateTask = await CreateEvent({
                        title: data?.title,
                        description: data?.description,
                        Dates: selectedRange,
                        start_date: `${currentYear}-${currentMonth}-${startDate.split("-")[2]} ${data?.start_Time}:00`,
                        end_date: `${currentYear}-${currentMonth}-${endDate.split("-")[2]} ${data?.end_Time}:00`,
                        colour: currentStripColor,
                        remind_Email: data?.remind_Email,
                        month: currentMonth,
                    })
                    if (CreateTask?.success == "success") {
                        handleCancel()
                        setSubmitLoading(false)
                        LoadCalender()
                        message.success('Schedule created')
                    }
                }

            }
        }
        catch (error) {
            console.error(error);
            setSubmitLoading(false)
        }
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            title: "",
            description: "",
            start_Time: "",
            end_Time: "",
            remind_Email: false,
        },
        mode: "onChange",
        resolver: yupResolver(scheduleSchema),
    });


    const sortedEvents = allEvent.sort((a, b) => b.Dates.length - a.Dates.length).filter(k => k.month == currentMonth);
    const countMap = {};
    sortedEvents.forEach((event) => {
        if (event?.Dates) {
            const RunArray = JSON.parse(event?.Dates)
            RunArray?.forEach((date) => {
                countMap[date] = (countMap[date] || 0) + 1;
            });
        }
    });
    const countsArray = Object.entries(countMap).map(([date, count]) => ({
        date: parseInt(date),
        count,
    }));
    countsArray.sort((a, b) => b.count - a.count);

    const handleChildMouseDown = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };
    const SingleDetailModal = (event, Id) => {
        event.stopPropagation();
        setIsDetail('singleDetail')
        setSingleEvent(Id)
    };
    const multiDetailModal = (event, Id, Day) => {
        event.stopPropagation();
        setIsDetail('allEvents')
        setCurrnetDateEvent({ Id: Id, Day: Day })
    };

    const DeleteEvent = async (E) => {
        const Event = await DeleteEventFunction(E)
        if (Event?.success == "success") {
            handleCancel()
            LoadCalender()
            message.success('Event Deleted')
        }
    }

    const UpdateEvent = async (E) => {
        setIsUpdate(true)
        setIsDetail('createForm')
        {
            sortedEvents?.filter(da => da?.event_id == E).map((ffe) => {
                setUpdateEventId(E)
                setUpdateStartDate(ffe?.start_date?.split(' ')[0])
                setUpdateEndDate(ffe?.end_date?.split(' ')[0])
                reset({
                    title: ffe?.title,
                    description: ffe?.description,
                    start_Time: ffe?.start_date?.split(' ')[1],
                    end_Time: ffe?.end_date?.split(' ')[1],
                    remind_Email: ffe?.is_email == "false" ? false : true,
                })
            })
        }
    }


    const changeMonth = (e) => {
        LoadCalender(e);
        setCurrentMonth(e)
    }

    const [skeletonLoader, setSkeltonLoader] = useState(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])

    return (
        <>
            <Modal footer={<></>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {isDetail == 'singleDetail' ?
                    <div className={Style.CreateTaskModal}>
                        {sortedEvents?.filter(da => da?.event_id == singleEvent).map((ffe) => {
                            return (
                                <>
                                    <div className="d-flex align-items-start">
                                        <h3>
                                            {ffe?.title}
                                        </h3>
                                    </div>
                                    <p className={Style.descriptionDetail}>{ffe?.description}</p>
                                    <div className="d-flex align-items-center">
                                        <div style={{ background: ffe?.colour }} className={Style.ColorDot}></div>
                                        <div className="p-3 d-flex align-items-center">
                                            <p style={{ fontWeight: 600 }} className="m-0">{ffe?.start_date?.split(" ")[0]}</p> <p className="mb-0 px-1">-</p>
                                            <p style={{ fontWeight: 600 }} className="m-0">{ffe?.end_date?.split(" ")[0]}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center pt-3 justify-content-end">
                                        <Popconfirm
                                            title="Delete the task"
                                            description="Are you sure to delete this task?"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={() => DeleteEvent(ffe?.event_id)}
                                        >
                                            <button>Delete</button>
                                        </Popconfirm>
                                        <button onClick={() => UpdateEvent(ffe?.event_id)} className="ml-2">Update</button>
                                    </div>
                                </>

                            )
                        })}
                    </div>
                    : isDetail == 'createForm' ?
                        <div className={Style.CreateTaskModal}>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <FormInput
                                    label={'Title'}
                                    placeholder={'Title Here'}
                                    id="title"
                                    name="title"
                                    type="text"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <TextArea
                                    label={'Description'}
                                    placeholder={'Description'}
                                    id="description"
                                    name="description"
                                    type="text"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormInput
                                    label={'Start Time'}
                                    placeholder={'Start Time'}
                                    id="start_Time"
                                    name="start_Time"
                                    type="time"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormInput
                                    label={'End Time'}
                                    placeholder={'End Time'}
                                    id="end_Time"
                                    name="end_Time"
                                    type="time"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormSwitch
                                    label={'Remind Email:'}
                                    id="remind_Email"
                                    name="remind_Email"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <div className="d-flex justify-content-end align-items-center">
                                    <button className={Style.SubmitBtn} type="submit">{submitLoading ? <Spin /> : 'Submit'}</button>
                                </div>
                            </form>
                        </div> :
                        isDetail == 'allEvents' ?
                            <div className={Style.CreateTaskModal}>
                                <div className="px-0 pb-3">
                                    <p className={`${Style.DetailPara} m-0`}>{currnetDateEvent?.Day}</p>
                                    <h1>{`0${currnetDateEvent?.Id + 1}`}</h1>
                                </div>
                                {sortedEvents?.map((ffe, Index) => {
                                    const CurrnetArray = JSON.parse(ffe?.Dates)
                                    let lastElementSave = CurrnetArray[CurrnetArray?.length - 1];
                                    let firstElementSave = CurrnetArray[0]
                                    return (
                                        <>
                                            {
                                                CurrnetArray.includes(currnetDateEvent?.Id + 1) ?
                                                    <>
                                                        <div onClick={(e) => SingleDetailModal(e, ffe?.event_id)} style={{
                                                            background: ffe?.colour,
                                                            width: CurrnetArray.includes(currnetDateEvent?.Id + 1) ? "100%" : '0%',
                                                            borderLeft: firstElementSave - 1 == currnetDateEvent?.Id ? '5px solid black' : '0px solid transparent',
                                                            borderRight: lastElementSave - 1 == currnetDateEvent?.Id ? '5px solid black' : '0px solid transparent',
                                                            marginTop: '3px',
                                                            borderTopLeftRadius: firstElementSave - 1 !== currnetDateEvent?.Id ? '30px' : '0px',
                                                            borderTopRightRadius: lastElementSave - 1 !== currnetDateEvent?.Id ? '30px' : '0px',
                                                            borderBottomLeftRadius: firstElementSave - 1 !== currnetDateEvent?.Id ? '30px' : '0px',
                                                            borderBottomRightRadius: lastElementSave - 1 !== currnetDateEvent?.Id ? '30px' : '0px',

                                                        }} className={Style.TaskStrip_detail}>
                                                            <p className={Style.LoadTitle}>{ffe?.title}</p>
                                                        </div>

                                                    </>
                                                    : null
                                            }
                                        </>

                                    )
                                })}
                            </div>
                            : null
                }
            </Modal>


            <div className="allPages">
                <SideBar
                    {...{ isMenuOpen, setMenuOpen }}
                />
                <div className="innerBox">
                    <TopBar
                        {...{ hideShowMenuClick }}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <SearchHeader isSearch={false} isCreate={false} title="Schedule Calender" isTotal={false} total={1} placeHolder={'Search Here'} admin={false} AdminBtn={"Add Company"} />
                                    </div>
                                    <div className="col-lg-4">
                                        <Select
                                            defaultValue={currentMonth}
                                            style={{ width: '100%' }}
                                            options={[
                                                { value: 1, label: 'January' },
                                                { value: 2, label: 'February' },
                                                { value: 3, label: 'March' },
                                                { value: 4, label: 'April' },
                                                { value: 5, label: 'May' },
                                                { value: 6, label: 'June' },
                                                { value: 7, label: 'July' },
                                                { value: 8, label: 'August' },
                                                { value: 9, label: 'September' },
                                                { value: 10, label: 'October' },
                                                { value: 11, label: 'November' },
                                                { value: 12, label: 'December' },
                                            ]}
                                            placeholder={"Month"}
                                            label={'Month'}
                                            id="month"
                                            name="month"
                                            onChange={(e) => changeMonth(e)}
                                        />
                                    </div>
                                </div>
                            </div>


                            {calenderLoader ?
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-12 px-4">
                                            <div className="row">
                                                {skeletonLoader?.map(data =>
                                                    <div className="col-2 p-0">
                                                        <div className={`p-2 d-flex align-items-center ${Style.MainCalenderBox}`}>
                                                            <Skeleton active />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-12 px-4">
                                            <div className="row" onMouseUp={() => showModal('createForm')}>
                                                {calenderData?.map((current, index) => {
                                                    const dateName = current?.Date?.split("-")[2]?.split(" ")[0]
                                                    let lastElement = selectedRange[selectedRange?.length - 1];
                                                    let firstElement = selectedRange[0]
                                                    const df = countsArray.find((element) => element?.date == index + 1)
                                                    const leftedCount = df?.count - 2
                                                    return (
                                                        <>
                                                            <div
                                                                key={dateName}
                                                                onMouseDown={(e) => handleMouseDown(e, dateName, current?.Date)}
                                                                onMouseEnter={(e) => handleMouseEnter(e, dateName, current?.Date)}
                                                                className={`col-2 p-0 sum`}>
                                                                <div className={Style.MainCalenderBox}>
                                                                    <div className="px-2">
                                                                        <p>{current?.Day_Name}</p>
                                                                        <h3>{dateName}</h3>
                                                                    </div>

                                                                    {selectedRange.includes(index + 1) ?
                                                                        <div style={{ background: currentStripColor, width: selectedRange.includes(index + 1) ? "100%" : '0%', borderLeft: firstElement - 1 == index ? '5px solid black' : '0px solid transparent', borderRight: lastElement - 1 == index ? '5px solid black' : '0px solid transparent' }} className={Style.TaskStrip}></div>
                                                                        : null}

                                                                    {df?.count > 2 ? sortedEvents?.slice(0, 2)?.map((ffe, Index) => {
                                                                        const CurrnetArray = JSON.parse(ffe?.Dates)
                                                                        let lastElementSave = CurrnetArray[CurrnetArray?.length - 1];
                                                                        let firstElementSave = CurrnetArray[0]
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    CurrnetArray.includes(index + 1) ?
                                                                                        <>
                                                                                            <div
                                                                                                onMouseDown={handleChildMouseDown}
                                                                                                onClick={(e) => { SingleDetailModal(e, ffe?.event_id) }}
                                                                                                style={{ background: ffe?.colour, width: CurrnetArray.includes(index + 1) ? "100%" : '0%', borderLeft: firstElementSave - 1 == index ? '5px solid black' : '0px solid transparent', borderRight: lastElementSave - 1 == index ? '5px solid black' : '0px solid transparent', marginTop: '3px' }} className={Style.TaskStrip}>
                                                                                                {firstElementSave - 1 == index ?
                                                                                                    <>
                                                                                                        <p className={Style.LoadTitle}>{ffe?.title}</p>
                                                                                                    </> : null}
                                                                                            </div>

                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </>

                                                                        )
                                                                    }
                                                                    ) : sortedEvents?.map((ffe, Index) => {
                                                                        const CurrnetArray = JSON.parse(ffe?.Dates)
                                                                        let lastElementSave = CurrnetArray[CurrnetArray?.length - 1];
                                                                        let firstElementSave = CurrnetArray[0]
                                                                        return (

                                                                            <>
                                                                                {
                                                                                    CurrnetArray.includes(index + 1) && ffe?.month == currentMonth ?
                                                                                        <div
                                                                                            onMouseDown={handleChildMouseDown}
                                                                                            onClick={(e) => { SingleDetailModal(e, ffe?.event_id) }}
                                                                                            style={{ background: ffe?.colour, width: CurrnetArray.includes(index + 1) ? "100%" : '0%', borderLeft: firstElementSave - 1 == index ? '5px solid black' : '0px solid transparent', borderRight: lastElementSave - 1 == index ? '5px solid black' : '0px solid transparent', marginTop: '3px' }} className={Style.TaskStrip}>
                                                                                            {firstElementSave - 1 == index ?
                                                                                                <>
                                                                                                    <p className={Style.LoadTitle}>{ffe?.title}</p>
                                                                                                </> : null}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </>

                                                                        )
                                                                    })}
                                                                    <>
                                                                        {isMoreModal ? null :
                                                                            <>
                                                                                {df?.count > 2 ?
                                                                                    <div
                                                                                        onMouseDown={handleChildMouseDown}
                                                                                        onClick={(e) => { multiDetailModal(e, index, current?.Day_Name) }}
                                                                                        style={{ width: "100%", marginTop: '3px' }} className={Style.TaskStrip_Transparent}>
                                                                                        <p className={Style.LoadTitle}>{leftedCount}+ more</p>
                                                                                    </div>
                                                                                    : null}
                                                                            </>
                                                                        }
                                                                    </>

                                                                </div>
                                                            </div>
                                                        </>

                                                    )
                                                }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

function mapStateToProps({ AllSchedule }) {
    return { AllSchedule };
}
export default connect(mapStateToProps, Schdule_Action)(Schedule);


// <div style={{ width: selectedRange.includes(index + 1) ? "100%" : '0%', borderLeft: firstElement - 1 == index ? '5px solid black' : '0px solid transparent', borderRight: lastElement - 1 == index ? '5px solid black' : '0px solid transparent' }} className={Style.TaskStrip}></div>
