import React, { useEffect, useState } from "react";
import "./assets/css/ManualUpsale.css";
import { Space, Table, Tag, Tooltip } from 'antd';
import { Popconfirm } from 'antd';
import { MdDeleteOutline } from 'react-icons/md';
import { PrimaryButton, SimpleButton } from "../../pages/component/Button";
import { CancelButton } from '../../pages/component/Button'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput, FormSelect } from '../../pages/component/formInput';
import * as ManualAction from "../../store/actions/ManualUpsale/index";
import { connect } from "react-redux";
// import { FaEdit } from 'react-icons/fa';
import * as yup from "yup";
import { message } from 'antd';
import { Link } from "react-router-dom";
// import baseUrl from '../config.json'




function ManualUpsale({
    cancel,
    isCode,
    Red_ManualUpsale,
    modal,
    get_AllPackages,
    get_AllLeadsss,
    SaveManualUpSale
}) {

    var get_access_token = localStorage.getItem("access_token");
    var get_company_code = localStorage.getItem("company_code");
    const [messageApi, contextHolder] = message.useMessage();
    const [isUpdateBtn, setUpdatebtn] = useState(false)
    // const [pageSize, setPageSize] = useState(10);
    const [isLoading, setLoading] = useState(false)
    const [isSavedEdu, setSavedEdu] = useState(false)
    const packeges = Red_ManualUpsale?.getPackage?.[0]?.res?.data
    const Clients = Red_ManualUpsale?.getLeads?.[0]?.res?.data


    const EditBack = () => {
        cancel('read')
    }

    // console.log(isCode, 'isCode')

    const ManualUpsaleSchema = yup.object().shape({
        name: yup.string().required("name is required"),
        package_id: yup.string().required("package_id is required"),
        amount: yup.string().required("amount is required"),
        payment_method: yup.string().required("payment_method is required"),
        email: yup.string().required("email is required"),
        number: yup.string().required("number is required"),
        lead_id: yup.string().required("lead_id is required"),
        status: yup.string().required("status is required"),
        // client_id: yup.string().required("client_id is required"),
        complete: yup.string().required("complete is required"),
        progress: yup.string().required("progress is required"),
        charge_id: yup.string().required("charge_id is required"),
        is_payment_recived: yup.string().required("is_payment_recived is required"),

    });

    useEffect(() => {
        get_AllPackages()
        get_AllLeadsss()
    }, [])


    // useEffect(() => {
    //     if (mode2 == "create") {
    //         reset({
    //             Emp_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Emp_name,
    //             Desig_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Desig_name,
    //             Dept_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Dept_name,
    //         });
    //     } else {
    //         reset(
    //             {
    //                 Emp_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Emp_name,
    //                 Desig_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Desig_name,
    //                 Dept_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Dept_name,
    //             },
    //         )
    //     }

    // }, [Red_AppointEducation?.data?.[0]?.res?.data?.[0]])




    // useEffect(() => {
    //     if (isUpdate) {
    //         reset({
    //             EduCode: Red_AppointEducation?.data?.[0]?.res?.data?.Edu_Code,
    //             EduYear: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Edu_Year,
    //             EduGrade: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Edu_Grade,
    //             Topflag: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Top_flag,
    //             institutecode: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.institute_code,
    //         });
    //     }


    // }, [Red_AppointEducation?.getSavedData?.[0]?.res?.data?.[0]])
    // console.log(Red_AppointEducation, 'R')

    // const EduData = Red_AppointEducation?.getEdu?.[0]?.res?.data
    // const InsData = Red_AppointEducation?.getInsti?.[0]?.res?.data
    // const GradeData = Red_AppointEducation?.getGrade?.[0]?.res?.data


    // ==================================================
    const submitForm = async (data) => {
        try {
            const isValid = await ManualUpsaleSchema.validate(data);
            if (isValid) {
                SaveForm(data)
            }
        } catch (error) {
            console.error(error);
        }
    };


    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {},
        mode: "onChange",
        resolver: yupResolver(ManualUpsaleSchema),
    });


    const SaveForm = async (data) => {
        setLoading(true)
        try {
            const response = await SaveManualUpSale({
                name: data?.name,
                package_id: data?.package_id,
                amount: data?.amount,
                payment_method: data?.payment_method,
                email: data?.email,
                number: data?.number,
                lead_id: data?.lead_id,
                status: data?.status,
                client_id: isCode,
                complete: data?.complete,
                progress: data?.progress,
                charge_id: data?.charge_id,
                is_payment_recived: data?.is_payment_recived,
            });

            if (response && response.success) {
                messageApi.success("Save Upsale");
                setTimeout(() => {
                    cancel('read')
                    setSavedEdu(true)
                }, 3000);
            } else {
                const errorMessage = response?.message || 'Failed to Save Upsale';
                messageApi.error(errorMessage);
            }
        } catch (error) {
            console.error("Error occurred while Save Upsale:", error);
            messageApi.error("An error occurred while Save Upsale");
        }
    };






    // const UdpateForm = async (data) => {
    //     setLoading(true)
    //     try {
    //         const response = await UpdateEducation({
    //             srNo: isUpdate,
    //             EduCode: data?.EduCode,
    //             EduYear: data?.EduYear,
    //             EduGrade: data?.EduGrade,
    //             Topflag: data?.Topflag,
    //             institutecode: data?.institutecode,
    //         });

    //         if (response && response.success) {
    //             messageApi.success("Save Upsale");
    //         } else {
    //             const errorMessage = response?.message || 'Failed Save Upsale ';
    //             messageApi.error(errorMessage);
    //         }
    //     } catch (error) {
    //         console.error("Error occurred while Save Upsale:", error);
    //         messageApi.error("An error occurred while Save Upsale");
    //     }
    // };





    return (
        <>
            {contextHolder}
            <div className="container ModalUpsale">
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="form-group formBoxCountry">
                                <FormInput
                                    label={'name'}
                                    placeholder='name'
                                    id="name"
                                    name="name"
                                    type='text'
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormInput
                                    label={'Email'}
                                    placeholder='name'
                                    id="email"
                                    name="email"
                                    type='text'
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormSelect
                                    label={'packages'}
                                    placeholder='packages'
                                    id="package_id"
                                    name="package_id"
                                    options={packeges?.map(item => ({
                                        id: item.package_id,
                                        name: item.name
                                        }))
                                    }
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormInput
                                    label={'amount'}
                                    placeholder='amount'
                                    id="amount"
                                    name="amount"
                                    type='number'
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormSelect
                                    label={'Payment method'}
                                    placeholder='Payment method'
                                    id="payment_method"
                                    name="payment_method"
                                    options={[
                                        {
                                            id: 'Credit Card',
                                            name: 'Credit Card',
                                        },
                                        {
                                            id: "Pay Pal",
                                            name: 'Pay Pal',
                                        },
                                    ]}
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormInput
                                    label={'number'}
                                    placeholder='number'
                                    id="number"
                                    name="number"
                                    type="number"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormSelect
                                    label={'leads'}
                                    placeholder={'leads'}
                                    id="lead_id"
                                    name="lead_id"
                                    options={
                                        Clients?.map(item => ({
                                            id: item.id,
                                            name: item.lead_source
                                        }))
                                    }
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormSelect
                                    label={'complete'}
                                    placeholder={'complete'}
                                    id="complete"
                                    name="complete"
                                    options={[
                                        {
                                            id: "true",
                                            name: 'True',
                                        },
                                        {
                                            id: "false",
                                            name: 'False',
                                        },
                                    ]}
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                {/* <FormInput
                                    label={'Client ID'}
                                    placeholder='number'
                                    id="number"
                                    name="client_id"
                                    type="client_id"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                /> */}
                                <FormSelect
                                    label={'status'}
                                    placeholder={'status'}
                                    id="status"
                                    name="status"
                                    options={[
                                        {
                                            id: "success",
                                            name: 'Success',
                                        },
                                        {
                                            id: "incomplete",
                                            name: 'incomplete',
                                        },
                                    ]}
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                {/* <FormSelect
                                    label={'complete'}
                                    placeholder={'complete'}
                                    id="complete"
                                    name="complete"
                                    options={[
                                        {
                                            id: "true",
                                            name: 'True',
                                        },
                                        {
                                            id: "false",
                                            name: 'False',
                                        },
                                    ]}
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                /> */}
                                <FormInput
                                    label={'charge id'}
                                    placeholder='charge id'
                                    id="charge_id"
                                    name="charge_id"
                                    type="text"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormInput
                                    label={'progress'}
                                    placeholder='progress'
                                    id="progress"
                                    name="progress"
                                    type="number"
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormSelect
                                    label={'Payment recived'}
                                    placeholder={'leads'}
                                    id="is_payment_recived"
                                    name="is_payment_recived"
                                    options={[
                                        {
                                            id: 1,
                                            name: 'Yes',
                                        },
                                        {
                                            id: 0,
                                            name: 'No',
                                        },
                                    ]}
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                            </div>
                            <div className='ManualUpSaleBtnBox'>
                                <CancelButton onClick={EditBack} title={'Cancel'} />
                                <SimpleButton type={'submit'} loading={isLoading} title="Save" />

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
function mapStateToProps({ Red_ManualUpsale }) {
    return { Red_ManualUpsale };
}
export default connect(mapStateToProps, ManualAction)(ManualUpsale);