import React, { useState, useEffect } from 'react'
import '../components/assets/css/CustomersLeads.css'
import OrderDataTable from 'react-data-table-component'
import secureLocalStorage from "react-secure-storage";
import userImg from '../assets/images/icons/userIco.png'
import { useNavigate, Link } from 'react-router-dom';
import { RxCross2 as Cross_ico } from "react-icons/rx";
import logo from '../assets/images/logoMish.png'
// =================================================
const config = require('../components/config.json')

const ViewAllNotificationsCom = () => {
    const navigate = useNavigate()
    const [dataLoader, setDataLoader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isLeadsData, setLeadsData] = useState([]);
    const [isFilterLeads, setFilterLeads] = useState([])
    var get_refresh_token = secureLocalStorage.getItem("refresh");
    var get_access_token = secureLocalStorage.getItem("access_token");
    var get_role = secureLocalStorage.getItem("role_id");
    const [page, setPage] = useState(0)
    const [Isrows, setRows] = useState([]);

    const [isModal, setModal] = useState(false)
    const [isLeadModal,setLeadModal] = useState(false)



    async function getNotifyofLeads() {
        await fetch(`${config['baseUrl']}/notifications/GetAllNotifications/${parseInt(page)}`, {
            method: "GET",
            headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` }
        }).then((response) => {
            return response.json()
        }).then(async (response) => {
            if (response.messsage == "unauthorized") {
                await fetch(`${config['baseUrl']}/notifications/GetAllNotifications/${parseInt(page)}`, {
                    method: "GET",
                    headers: { "content-type": "application/json", "refereshToken": `Bareer ${get_refresh_token}` }
                }).then(response => {
                    return response.json()
                }).then(response => {
                    secureLocalStorage.setItem("refresh", response.referesh_token);
                    secureLocalStorage.setItem("access_token", response.access_token);
                    setLeadsData(response.data)
                    setFilterLeads(response.data)
                    setDataLoader(true)
                    setRows(response.totalRows)
                }).catch((errs) => {
                    console.log("errs.data", errs)
                }).finally(() => { setLoading(false) })
            }
            else if (response.messsage == "timeout error") {
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = '/'
            }
            else {
                setLeadsData(response.data)
                setFilterLeads(response.data)
                setDataLoader(true)
                setRows(response.totalRows)
                console.log("response.data", response.data)
            }
        }).catch((errs) => {
            console.log("errs.data", errs)
        }).finally(() => { setLoading(false) })
    }


    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                borderTop: "1px solid #80808021",
                borderLeft: "1px solid #80808021",
                borderRight: "1px solid #80808021",
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                borderBottom: "1px solid #80808021",
                borderLeft: "1px solid #80808021",
                borderRight: "1px solid #80808021",
            },
        },
    };
    const columns = [
        {
            name: "R.No",
            cell: (row, index) => index + 1,
            grow: 0,
            minWidth: "50px",
        },
        {
            name: "Leads Details",
            selector: (row) =>
                <div className='dataTableBox'
                    onClick={() => {
                        if (row?.task_id !== 0) {
                            getTask(row.task_id)
                            setModal(true)
                            setTaskDeleteMsg(row?.name)
                            console.log("first",row)
                        }else if(row?.lead_id !== 0){
                            getLeadsById(row.lead_id)
                            setLeadModal(true)
                        }
                    }}
                >
                    <div className='dataTableFlexBox'>
                        <h5>
                            <img src={userImg} />
                        </h5>
                        <h6>
                            <span className='tableName'>
                                {row.heading}
                            </span>
                            <span className='tableEmail'>
                                {row.description}
                            </span>
                        </h6>
                    </div>
                </div>
        },
    ]
    const handlePageChange = page => {
        setPage(parseInt(page) - 1)
    }
    const [getLeadData,setLeadData] = useState([])
    const getLeadsById = async (e) => {
        await fetch(`${config['baseUrl']}/lead/GetCustomerLeadsById/${e}`, {
            method: "GET",
            headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` }
        }).then((response) => {
            return response.json()
        }).then(async (response) => {
            if (response.messsage == "unauthorized") {
                await fetch(`${config['baseUrl']}/lead/GetCustomerLeadsById/${e}`, {
                    method: "GET",
                    headers: { "content-type": "application/json", "refereshToken": `Bareer ${get_refresh_token}` }
                }).then(response => {
                    return response.json()
                }).then(response => {
                    if (response.messsage == "timeout error") { navigate('/') }
                    else {
                        secureLocalStorage.setItem("refresh", response.referesh_token);
                        secureLocalStorage.setItem("access_token", response.access_token);
                        setLeadData(response?.data)
                    }
                }).catch((errs) => {
                })
            }
            else {
                setLeadData(response?.data)
            }
        }).catch((errs) => {
        })
    }
    const [getDataOfTask, setDataOfTask] = useState([])
    const [isTaskDeleteMsg, setTaskDeleteMsg] = useState(false)
    const getTask = async (e) => {
        await fetch(`${config['baseUrl']}/tasks/getTasksById/${e}`, {
            method: "GET",
            headers: { "content-type": "application/json", "accessToken": `Bareer ${get_access_token}` }
        }).then((response) => {
            return response.json()
        }).then(async (response) => {
            if (response.messsage == "unauthorized") {
                await fetch(`${config['baseUrl']}/tasks/getTasksById/${e}`, {
                    method: "GET",
                    headers: { "content-type": "application/json", "refereshToken": `Bareer ${get_refresh_token}` }
                }).then(response => {
                    return response.json()
                }).then(response => {
                    if (response.messsage == "timeout error") { navigate('/') }
                    else {
                        secureLocalStorage.setItem("refresh", response.referesh_token);
                        secureLocalStorage.setItem("access_token", response.access_token);
                        setDataOfTask(response.data)
                    }
                }).catch((errs) => {
                })
            }
            else {
                setDataOfTask(response.data)
            }
        }).catch((errs) => {
        })
    }


    useEffect(() => {
        getNotifyofLeads(page)
    }, [page])

    return (
        <>

            <div className="CusLeadsBox">
                <h4 className='CusLeadsBoxHeadOne'>Leads Notifications</h4>
                <div className="innerCusLeadsBox d-block">
                    <h4 className='CusLeadsBoxHeadTwo' style={{ textAlign: "center" }}>Leads Notifications</h4>
                </div>
                <ul>
                    {/* {isErrGetAllPkgs && (
                        <li className={`alert alert-warning` + " " + "m-3"}>{`${isErrGetAllPkgs}`}</li>
                    )} */}

                </ul>
                {loading && (
                    <div className="loaderBox">
                        <div className="loader">
                            <div className="one"></div>
                            <div className="two"></div>
                            <div className="three"></div>
                            <div className="four"></div>
                        </div>
                        {/* <span>Loading...</span> */}
                    </div>
                )}
                {dataLoader && (
                    <>
                        <OrderDataTable
                            columns={columns}
                            data={isLeadsData}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={Isrows}
                            paginationComponentOptions={{
                                noRowsPerPage: true,
                            }}
                            customStyles={customStyles}
                            onChangePage={handlePageChange}
                        />
                    </>
                )}
            </div>

            {isModal ? (
                <>
                {console.log("getDataOfTask",getDataOfTask.length)}
                    { getDataOfTask.length > 0 ?
                        <div className="addLeadsModalBox">
                            <div className="addLeadsBox">
                                <Cross_ico onClick={() => {
                                    setModal(false)
                                }}
                                    style={{
                                        position: "absolute", top: "15px", right: "20px",
                                        color: "#a09494", fontSize: "22px",
                                        border: "1px solid #a09494", padding: "0 3px", borderRadius: "10px"
                                    }}
                                />
                                <h4 style={{ marginTop: "30px", marginBottom: "10px" }}>
                                    <img src={logo} alt="" className='' />
                                </h4>
                                <form>
                                    <div className="leadsScrollBox">
                                        <div className="inneraddLeadsBox">
                                            {getDataOfTask?.[0]?.title && (
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.title !== "" ? getDataOfTask?.[0]?.title : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getDataOfTask?.[0]?.assignee_name && (
                                                <div className="form-group">
                                                    <label>Assignee Name</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.assignee_name !== "" ? getDataOfTask?.[0]?.assignee_name : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getDataOfTask?.[0]?.summary && (
                                                <div className="form-group">
                                                    <label>Summary</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.summary !== "" ? getDataOfTask?.[0]?.summary : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getDataOfTask?.[0]?.project_name && (
                                                <div className="form-group">
                                                    <label>Project Name</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.project_name !== "" ? getDataOfTask?.[0]?.project_name : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getDataOfTask?.[0]?.status && (
                                                <div className="form-group">
                                                    <label>Status</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.status !== "" ? getDataOfTask?.[0]?.status : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getDataOfTask?.[0]?.created_by_name && (
                                                <div className="form-group">
                                                    <label>Created By Name</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.created_by_name !== "" ? getDataOfTask?.[0]?.created_by_name : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getDataOfTask?.[0]?.estimated_time && (
                                                <div className="form-group">
                                                    <label>Estimated Time</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.estimated_time !== "" ? getDataOfTask?.[0]?.estimated_time : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getDataOfTask?.[0]?.created_at && (
                                                <div className="form-group">
                                                    <label>Created at</label>
                                                    <input type="text" className="form-control" value={getDataOfTask?.[0]?.created_at !== "" ? getDataOfTask?.[0]?.created_at : "Not Found"} readOnly />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> :
                        <div className="addLeadsModalBox">
                            <div className="addLeadsBox">
                                <Cross_ico onClick={() => {
                                    setModal(false)
                                }}
                                    style={{
                                        position: "absolute", top: "15px", right: "20px",
                                        color: "#a09494", fontSize: "22px",
                                        border: "1px solid #a09494", padding: "0 3px", borderRadius: "10px"
                                    }}
                                />
                                <h4 style={{ marginTop: "30px", marginBottom: "10px" }}>
                                    <img src={logo} alt="" className='' />
                                </h4>
                                <form>
                                    <div className="leadsScrollBox">
                                        <div className="inneraddLeadsBox">
                                            <div className="form-group">
                                                <label>Task has been Deleted</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </>

            ) : false}

            {isLeadModal ? (
                <>
                    {
                        getLeadData.length > 0 ?
                        <div className="addLeadsModalBox">
                            <div className="addLeadsBox">
                                <Cross_ico onClick={() => {
                                    setLeadModal(false)
                                }}
                                    style={{
                                        position: "absolute", top: "15px", right: "20px",
                                        color: "#a09494", fontSize: "22px",
                                        border: "1px solid #a09494", padding: "0 3px", borderRadius: "10px"
                                    }}
                                />
                                <h4 style={{ marginTop: "30px", marginBottom: "10px" }}>
                                    <img src={logo} alt="" className='' />
                                </h4>
                                <form>
                                    <div className="leadsScrollBox">
                                        <div className="inneraddLeadsBox">
                                            {getLeadData?.[0]?.cutomer_name && (
                                                <div className="form-group">
                                                    <label>Customer Name</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.cutomer_name !== "" ? getLeadData?.[0]?.cutomer_name : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.about_project && (
                                                <div className="form-group">
                                                    <label>About Project</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.about_project !== "" ? getLeadData?.[0]?.about_project : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.agent_name && (
                                                <div className="form-group">
                                                    <label>Agent Name</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.agent_name !== "" ? getLeadData?.[0]?.agent_name : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.budget && (
                                                <div className="form-group">
                                                    <label>Budget</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.budget !== "" ? getLeadData?.[0]?.budget : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.price && (
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.price !== "" ? getLeadData?.[0]?.price : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.to_received && (
                                                <div className="form-group">
                                                    <label>to Eeceived</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.to_received !== "" ? getLeadData?.[0]?.to_received : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.custom_package && (
                                                <div className="form-group">
                                                    <label>Package</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.custom_package !== "" ? getLeadData?.[0]?.custom_package : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.email && (
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.email !== "" ? getLeadData?.[0]?.email : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.number && (
                                                <div className="form-group">
                                                    <label>Number</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.number !== "" ? getLeadData?.[0]?.number : "Not Found"} readOnly />
                                                </div>
                                            )}
                                            {getLeadData?.[0]?.payment_method && (
                                                <div className="form-group">
                                                    <label>Payment Method</label>
                                                    <input type="text" className="form-control" value={getLeadData?.[0]?.payment_method !== '' ? getLeadData?.[0]?.payment_method : "Not Found"} readOnly />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> :
                        <div className="addLeadsModalBox">
                            <div className="addLeadsBox">
                                <Cross_ico onClick={() => {
                                    setLeadModal(false)
                                }}
                                    style={{
                                        position: "absolute", top: "15px", right: "20px",
                                        color: "#a09494", fontSize: "22px",
                                        border: "1px solid #a09494", padding: "0 3px", borderRadius: "10px"
                                    }}
                                />
                                <h4 style={{ marginTop: "30px", marginBottom: "10px" }}>
                                    <img src={logo} alt="" className='' />
                                </h4>
                                <form>
                                    <div className="leadsScrollBox">
                                        <div className="inneraddLeadsBox">
                                            <div className="form-group">
                                                <label>Lead has been Deleted</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </> 
            ) : false}

        </>
    )
}

export default ViewAllNotificationsCom