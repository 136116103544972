import {
    TASK_START,
    TASK_COMPLETE,
    TASK_END,
} from '../../actions/types'


const initState = {
    data: [],
    loading: false,
}

const TaskReducer = (state = initState, action) => {
    switch (action.type) {
        case TASK_START:
            return {
                ...state,
                loading: action.payload,
            };

        case TASK_COMPLETE:
            return {
                ...state,
                data: action.payload,
            };
        case TASK_END:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default TaskReducer