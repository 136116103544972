import * as yup from "yup";


const scheduleSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
    start_Time: yup.string().required("Start time is required"),
    end_Time: yup.string().required("End time is required"),
    remind_Email: yup.string().required("Remind email is required"),
});


export { scheduleSchema };