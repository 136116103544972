import React, { useEffect, useState, useRef } from "react";
import SideBar from "../../components/SideBar";
import TopBar from "../../components/topBar";
import Footer from "../../components/Footer";
import SearchHeader from '../searchHeader/searchHeader'
import { Button, Modal, Table, message, Popconfirm } from 'antd';
import style from './addcompany.module.css'
import { connect } from "react-redux";
import * as AllCompany_Action from "../../store/actions/allCompany/index";
import { FormInput, FormNumberInput, Input } from '../component/formInput';
import { createCompanySchema } from './companySchema';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const AddCompany = ({ getCompanyList, InsertComapny, deleteCompany, companyDetail,updateCompany}) => {
    const [loading, setLoading] = useState(false)
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [companyData, setCompanyData] = useState([])
    const [companyDetailState, setCompanyDetailState] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [total, setTotal] = useState(0);
    const [file, setFile] = useState();
    const [mode, setMode] = useState();
    const [pngOnly, setPngOnly] = useState(false);

    const showModal = (R) => {
        if (R == "Update") {
            setMode("Update")
        }
        else {
            setCompanyDetailState([])
        }
        setIsModalOpen(true);
        reset({
            name: "",
            email: "",
            address: "",
            number: "",
        })
        setFileError(false)
        setPngOnly(false)
    };
    const handleOk = () => {
        setIsModalOpen(false);
        reset({
            name: "",
            email: "",
            address: "",
            number: "",
        })
        setFileError(false)
        setPngOnly(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        reset({
            name: "",
            email: "",
            address: "",
            number: "",
        })
        setFileError(false)
        setPngOnly(false)
    };
    const hideShowMenuClick = () => {
        setMenuOpen(current => !current)
    }
    const deleteCompanyFunc = async (data) => {
        const useData = await deleteCompany(data)
        if (useData?.success == "success") {
            message.success('Successfully Deleted')
            loadData()
        }
        else {
            message.error('something went wrong')
        }
    };

    const updateCompanyFunc = async (R) => {
        const load = await companyDetail(R)
        if (load?.success == "success") {
            const detail = load?.data[0]
            setCompanyDetailState(load?.data)
            showModal("Update")
            reset({
                name: detail?.company_name,
                email: detail?.company_email,
                address: detail?.company_address,
                number: detail?.company_phone_number,
            })
        }
        else {
            message.error('something went wrong')
        }
    };

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
        },
        {
            title: 'Comapny Email',
            dataIndex: 'company_email',
            key: 'company_email',
        },

        {
            title: 'Comany Address',
            key: 'company_address',
            dataIndex: 'company_address',
        },

        {
            title: 'Phone Number',
            key: 'company_phone_number',
            dataIndex: 'company_phone_number',
        },
        {
            title: 'Action',
            key: 'Action',
            render: (P) => (
                <>
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => { deleteCompanyFunc(P?.company_code) }}
                    >
                        <Button>Delete</Button>
                    </Popconfirm>
                    <Button onClick={() => { updateCompanyFunc(P?.company_code) }} className="ml-2">Update</Button>
                </>
            ),
        },
    ];

    const loadData = async () => {
        setLoading(true)
        const useData = await getCompanyList()
        if (useData?.success == "success") {
            setCompanyData(useData?.data)
            setTotal(useData?.TotalCompanies)
            setLoading(false)
        }
        else {
            setLoading(false)
            message.error('something went wrong')
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const submitForm = async (data) => {
        if (mode == "Update") {
            try {
                const response = await updateCompany({
                    company_name:data?.name,
                    company_email:data?.email,
                    company_address:data?.address,
                    company_phone_number:data?.number,
                    company_code:companyDetailState[0]?.company_code
                })
                if (response?.success == "success") {
                    handleCancel()
                    message.success('Successfully Updated')
                    loadData()
                }
            }
            catch (error) {
                console.error(error);
            }
        }
        else {
            try {
                const formData = new FormData()
                const isValid = await createCompanySchema.validate(data);
                if (isValid) {
                    if (file.target.files[0]?.type == 'image/png') {
                        formData.append('company_name', data?.name)
                        formData.append('company_email', data?.email)
                        formData.append('file', file.target.files[0])
                        formData.append('company_address', data?.address)
                        formData.append('company_phone_number', data?.number)
                        const response = await InsertComapny(formData)
                        if (response?.success == "success") {
                            handleCancel()
                            message.success('Successfully Created')
                            loadData()
                        }
                    }
                    else {
                        setPngOnly(true)
                    }
                }
                else {
                    setFileError(true)
                }
            } catch (error) {
                console.error(error);
            }
        }

    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            address: "",
            number: "",
        },
        mode: "onChange",
        resolver: yupResolver(createCompanySchema),
    });

    return (
        <>
            <Modal footer={<></>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className={style.CreateCompanyModal}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <FormInput
                            label={'Company Name'}
                            placeholder={'Name'}
                            id="name"
                            name="name"
                            type="text"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />
                        <FormInput
                            label={'Email'}
                            placeholder={'Email'}
                            id="email"
                            name="email"
                            type="text"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />

                        <FormInput
                            label={'Address'}
                            placeholder={'Address'}
                            id="address"
                            name="address"
                            type="text"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />

                        <FormNumberInput
                            label={'Phone Number'}
                            placeholder={'Phone Number'}
                            id="number"
                            name="number"
                            showLabel={true}
                            errors={errors}
                            control={control}
                        />

                        {companyDetailState.length > 0 ? null :
                            <div className="d-flex flex-column px-2">
                                <label>Company Logo</label>
                                <input onChange={(e) => {
                                    setFileError(false)
                                    setFile(e)
                                    setPngOnly(false)
                                }} type="file" />
                                {fileError ? <p style={{ color: 'red' }}>file id required</p> : null}
                                {pngOnly ? <p style={{ color: 'red' }}>PNG file valid only</p> : null}
                            </div>
                        }

                        <div className="d-flex align-items-center justify-content-end px-2 pt-3">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className="allPages">
                <SideBar
                    {...{ isMenuOpen, setMenuOpen }}
                />
                <div className="innerBox">
                    <TopBar
                        {...{ hideShowMenuClick }}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <SearchHeader isSearch={false} isCreate={true} onClick={showModal} title="Company" total={total} placeHolder={'Search Here'} admin={true} AdminBtn={"Add Company"} />
                            </div>
                            <div className={style.tableShadow}>
                                <Table loading={loading} columns={columns} dataSource={companyData} />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>

    )
}

function mapStateToProps({ allCompany }) {
    return { allCompany };
}
export default connect(mapStateToProps, AllCompany_Action)(AddCompany);