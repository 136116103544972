export const TASK_RELOAD_TRUE = "TASK_RELOAD_TRUE";
export const TASK_RELOAD_FALSE = "TASK_RELOAD_FALSE";



export const GET_ALLOWANCE_START = "GET_ALLOWANCE_START";
export const GET_ALLOWANCE_COMPLETE = "GET_ALLOWANCE_COMPLETE";
export const GET_ALLOWANCE_END = "GET_ALLOWANCE_END";

export const TASK_START = "TASK_START";
export const TASK_COMPLETE = "TASK_COMPLETE";
export const TASK_END = "TASK_END";


export const Get_Leads_Start = "Get_Leads_Start";
export const Get_Leads_Data = "Get_Leads_Data";
export const Get_Project_Data = "Get_Project_Data";
export const Get_Order_Data = "Get_Order_Data";
export const Get_Leads_End = "Get_Leads_End";



export const Get_ManualUpsale_Start = "Get_ManualUpsale_Start";
export const Get_ManualUpsale_Data = "Get_ManualUpsale_Data";
export const Get_ManualUpsale_Leads_Data = "Get_ManualUpsale_Leads_Data";
export const Get_ManualUpsale_Package_Data = "Get_ManualUpsale_Package_Data";
export const Get_ManualUpsale_End = "Get_ManualUpsale_End";


export const Get_Upsale_Start = "Get_Upsale_Start";
export const Get_Upsale_data = "Get_Upsale_data";
export const Get_Frontsale_data = "Get_Frontsale_data";
export const Get_Upsale_end = "Get_Upsale_end";