import {
    Get_Leads_Data,
    Get_Project_Data,
    Get_Order_Data,
    Get_Leads_Start,
    Get_Leads_End,
} from '../../actions/types'


const initState = {
    data: [],
    getProject:[],
    getOrder :[],
    dataSingle: [],
    loading: false,
}

const Red_LeadMonthAndYear  = (state = initState, action) => {
    switch (action.type) {
        case Get_Leads_Start:
            return {
                ...state,
                loading: action.payload,
            };
        case Get_Leads_Data:
            return {
                ...state,
                data: action.payload,
            };
        case Get_Project_Data:
            return {
                ...state,
                getProject: action.payload,
            };
        case Get_Order_Data:
            return {
                ...state,
                getOrder: action.payload,
            };
        case Get_Leads_End:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default Red_LeadMonthAndYear