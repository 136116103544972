import {
    Get_ManualUpsale_Data,
    Get_ManualUpsale_Start,
    Get_ManualUpsale_Leads_Data,
    Get_ManualUpsale_Package_Data,
    Get_ManualUpsale_End,
} from '../../actions/types'


const initState = {
    data: [],
    getLeads: [],
    getPackage: [],
    dataSingle: [],
    loading: false,
}

const Red_ManualUpsale = (state = initState, action) => {
    switch (action.type) {
        case Get_ManualUpsale_Start:
            return {
                ...state,
                loading: action.payload,
            };
        case Get_ManualUpsale_Package_Data:
            return {
                ...state,
                getPackage: action.payload,
            };
        case Get_ManualUpsale_Data:
            return {
                ...state,
                data: action.payload,
            };
        case Get_ManualUpsale_Leads_Data:
            return {
                ...state,
                getLeads: action.payload,
            };
        case Get_ManualUpsale_End:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default Red_ManualUpsale