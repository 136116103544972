import React from'react'

const Error=()=>{
return(
    <>
    <div class="notfound-404">
        <div className="notfound-404-inner">
            <h1>404</h1>
            <h2>Page not found</h2>
        </div>
    </div>
    </>
)
}
export default Error