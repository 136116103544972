import React from "react";
import style from './searchHeader.module.css'
import { Button } from "antd";

const SearchHeader = ({ title, total, placeHolder, AdminBtn, onClick, isCreate, isSearch, isTotal }) => {
    return (
        <div className={style.MainHeaderSearch}>
            <div className="d-flex align-items-end">
                <h2>{title}</h2>
                {isTotal ?
                    <p className="mb-0 ml-2">Total: {total}</p>
                    : null}
            </div>
            <div className="d-flex align-items-center">
                {isSearch ?
                    <input placeholder={placeHolder} type="text" />
                    : null}

                {isCreate ?
                    <button onClick={onClick}>{AdminBtn}</button>
                    : null}
            </div>
        </div>
    )
}
export default SearchHeader