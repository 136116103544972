// @flow
import React, { useEffect, useState } from "react";
import styled from "@xstyled/styled-components";
import { colors } from "@atlaskit/theme";
import PropTypes from "prop-types";
import Column from "./Column";
import reorder, { reorderQuoteMap } from "../reorder";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import * as AllTask_Action from "../../../../store/actions/Task/index";
import { connect } from "react-redux";
import { message } from "antd";


const Container = styled.div`
  background-color: ${colors.B100};
  min-height: 100vh;
  /* like display:flex but will allow bleeding over the window width */
  min-width: 100vw;
  display: inline-flex;
`;
const Board = ({
  isCombineEnabled,
  initial,
  useClone,
  containerHeight,
  withScrollableColumns,
  UpdateTasks,
  StartTaskTime,
  GetTaskDetail,
  EndTaskTime
}) => {
  const [columns, setColumns] = useState(initial);
  const [ordered, setOrdered] = useState(Object.keys(initial));
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setOrdered(Object.keys(initial))
    setColumns(initial)
  }, [initial])
  const [messageApi, contextHolder] = message.useMessage();

  const onDragEnd = async (result) => {
    if (result?.destination?.droppableId !== result?.source?.droppableId) {
      messageApi.open({
        type: 'loading',
        content: 'Updating...',
        duration: 0,
      });
      const responseDetail = await GetTaskDetail(result?.draggableId)
      if (responseDetail?.success == "success") {
        // console.log(responseDetail,'asdasdsdkk22')
        const response = await UpdateTasks({
          id: result?.draggableId,
          status_code: result?.destination?.droppableId == "Todo" ? 1 :
            result?.destination?.droppableId == "InProgress" ? 2 :
              result?.destination?.droppableId == "QA" ? 3 :
                result?.destination?.droppableId == "Complete" ? 4 : 1,
          assignee_id: responseDetail?.data[0]?.assignee_id,
        })
        messageApi.destroy()
        if (response?.success == "success") {
          messageApi.destroy()
          const responseTimeDetail = await StartTaskTime({
            task_id: result?.draggableId,
            from_destination: result?.source?.droppableId,
            to_destination: result?.destination?.droppableId,
            moved_by_id: localStorage.getItem('user_id')
          })
          message.success('Status Successfully Update')
          setLoader(false)
          setTimeout(() => {
            setLoader(true)
          }, 3000);
        }
      }
    }


    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);
        return;
      }
      const column = columns[result.source.droppableId];
      const withQuoteRemoved = [...column];

      withQuoteRemoved.splice(result.source.index, 1);

      const orderedColumns = {
        ...columns,
        [result.source.droppableId]: withQuoteRemoved
      };
      setColumns(orderedColumns);
      return;
    }
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    if (result.type === "COLUMN") {
      const reorderedorder = reorder(ordered, source.index, destination.index);
      setOrdered(reorderedorder);
      return;
    }
    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination
    });
    setColumns(data.quoteMap);
  };
  return (
    <>
     {contextHolder}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={Boolean(containerHeight)}
          isCombineEnabled={isCombineEnabled}
        >
          {(provided) => {
            return (
              <Container ref={provided.innerRef} {...provided.droppableProps}>
                {ordered.map((key, index) => {
                  return (
                    <Column
                      key={key}
                      index={index}
                      title={key}
                      quotes={columns[key]}
                      isScrollable={withScrollableColumns}
                      isCombineEnabled={isCombineEnabled}
                      useClone={useClone}
                    />
                  )
                })}
                {provided.placeholder}
              </Container>
            )
          }}
        </Droppable>
      </DragDropContext>
    </>
  );
};

Board.defaultProps = {
  isCombineEnabled: false
};

Board.propTypes = {
  isCombineEnabled: PropTypes.bool
};


function mapStateToProps({ TaskReducer }) {
  return { TaskReducer };
}
export default connect(mapStateToProps, AllTask_Action)(Board);
