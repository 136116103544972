import React from 'react'
import Routing from './routing/routing'
import { Provider } from 'react-redux';
import store from './store/index';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


function App() {
  return (
    <>
      <Provider store={store}>
        <Routing />
      </Provider>
    </>
  );
}

export default App;
