import {
    Get_ManualUpsale_Data,
    Get_ManualUpsale_Leads_Data,
    Get_ManualUpsale_Package_Data,
    Get_ManualUpsale_Start,
    Get_ManualUpsale_End


} from '../types'
import { message } from 'antd';
import baseUrl from '../../config.json'




export const get_AllPackages = (body) => async (dispatch) => {
    try {
        dispatch({
            type: Get_ManualUpsale_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/packages/getAllSubPakages`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const res = await response.json()
            dispatch({
                type: Get_ManualUpsale_Package_Data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_ManualUpsale_End,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_ManualUpsale_End,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}

export const get_AllLeadsss = (body) => async (dispatch) => {
    try {
        dispatch({
            type: Get_ManualUpsale_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/lead/GetDropdownCustomerLeads`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const res = await response.json()
            dispatch({
                type: Get_ManualUpsale_Leads_Data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_ManualUpsale_End,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_ManualUpsale_End,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}

export const UpsaleGird = (body) => async (dispatch) => {
    console.log(body ,  'body')
    try {
        dispatch({
            type: Get_ManualUpsale_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/order/GetOrdersByClientID`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "clientID": body
            })
        });
        if (response.status === 200) {
            const res = await response.json()
            console.log(res , 'res')
            dispatch({
                type: Get_ManualUpsale_Data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_ManualUpsale_End,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_ManualUpsale_End,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}


export const SaveManualUpSale = (body) => async (dispatch) => {
    const response = await fetch(`${baseUrl.baseUrl}/order/CreateManualUpsales`, {
        method: "POST",
        headers: {
            'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "name": body?.name,
            "package_id": body?.package_id,
            "amount": body?.amount,
            "payment_method": body?.payment_method,
            "email": body?.email,
            "number": body?.number,
            "lead_id": body?.lead_id,
            "status": body?.status,
            "client_id": body?.client_id,
            "complete": body?.complete,
            "progress": body?.progress,
            "charge_id": body?.charge_id,
            "is_payment_recived": body?.is_payment_recived
        })
    });
    const res = await response.json();
    if (res?.success) {
        return res;
    } else {
        return res;
    }
}



export const UpdateManualSale = (iscode) => async (dispatch) => {
    const response = await fetch(`${baseUrl.baseUrl}/order/UpdateManualUpsales`, {
        method: "POST",
        headers: {
            'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "id": iscode?.id
      
        })
    });
    const res = await response.json();
    if (res?.success) {
        return res;
    } else {
        return res;
    }
}