import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PrimaryButton, CancelButton } from "../../pages/component/Button"
import * as ManualUpsale_Actions from "../../store/actions/ManualUpsale/index";
import { MdDeleteOutline } from 'react-icons/md';
import './assets/css/ManualUpsale.css'
import { FaEdit } from 'react-icons/fa';
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import { yupResolver } from "@hookform/resolvers/yup";
import { Space, Table, Tag, Tooltip } from "antd";
import * as yup from "yup";
import { Popconfirm } from 'antd';
import { message } from 'antd';
// import baseUrl from './config.json'

const ViewUpsale = ({ Red_ManualUpsale, cancel, UpsaleGird, isCode, UpdateManualSale }) => {

    const [messageApi, contextHolder] = message.useMessage();
    var get_access_token = localStorage.getItem("access_token");
    const EditBack = () => {
        cancel('read')
    }


    useEffect(() => {
        UpsaleGird(isCode)
    }, [])

   
    
      
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "number",
            dataIndex: "number",
            key: "number",
        },
        {
            title: "email",
            dataIndex: "email",
            key: "email",
        },

        {
            title: "amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "payment method",
            dataIndex: "payment_method",
            key: "payment_method",
        },
     
        {
            title: "front sale",
            dataIndex: "frontsale",
            key: "frontsale",
        },
        {
            title: "status",
            dataIndex: "status",
            key: "status",
        },
        
        {
            title: "Update",
            key: "Update",
            render: (data) => (
                <Space size="middle">
                    <button
                        onClick={() => UpdateSale(data?.id)}
                        className="editBtn"
                    >
                        <FaEdit />
                    </button>

                </Space>
            ),
        },
    ];


    const columns2 = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "number",
            dataIndex: "number",
            key: "number",
        },
        {
            title: "email",
            dataIndex: "email",
            key: "email",
        },
    
        {
            title: "amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "payment method",
            dataIndex: "payment_method",
            key: "payment_method",
        },
      
        {
            title: "front sale",
            dataIndex: "frontsale",
            key: "frontsale",
        },
        {
            title: "status",
            dataIndex: "status",
            key: "status",
        },

        // {
        //     title: "Action",
        //     key: "action",
        //     render: (data) => (
        //         <Space size="middle">
        //             <button
        //                 // onClick={() => EditPage("Edit", data?.City_code)}
        //                 className="editBtn"
        //             >
        //                 <FaEdit />
        //             </button>
        //         </Space>
        //     ),
        // },
    ];

    const UpdateSale = async (id) => {
        try {
            const response = await UpdateManualSale({
                id: id,
            });
            // console.log(response, 'response')

            if (response && response.success) {
                messageApi.success("Update Sale");
                setTimeout(() => {
                    cancel('read')
                }, 3000);
            } else {
                const errorMessage = response?.message || 'Failed to Update Sale';
                messageApi.error(errorMessage);
            }
        } catch (error) {
            console.error("Error occurred while Update Sale:", error);
            messageApi.error("An error occurred while Update Sale");
        }
    };
  




    return (
        <>

            {contextHolder}
            <div className="container mt-2 MainUpsalegrid">
                <div className="row">
                   
                        <div className="col-lg-12 maringClass">
                            <div className="d-flex justify-content-between align-item-center">
                                <h6>Manual Sales</h6>
                                {/* <CancelButton onClick={EditBack} title={'Cancel'} /> */}
                                <MdCancel onClick={EditBack} className="Icon" />
                            </div>
                            <div>
                            </div>
                            <div>

                                <Table
                                    columns={columns}
                                    loading={false}
                                dataSource={Red_ManualUpsale?.data?.[0]?.res?.ManualUpsales}
                                    scroll={{ x: 10 }}
                                // pagination={{
                                //     defaultCurrent: page,
                                //     total: Red_LeadMonthAndYear?.data?.[0]?.res?.data3,
                                //     onChange: (p) => {
                                //         setPage(p);
                                //     },
                                //     pageSize: pageSize,
                                // }}
                                />

                            </div>
                        </div>   
                        
                    <div className="col-lg-12 maringClass">
                        <div className="d-flex justify-content-between align-item-center">
                            <h6>Orders</h6>
                            {/* <MdCancel onClick={EditBack} className="Icon" /> */}

                        </div>
                        <div>
                        </div>
                        <div>

                            <Table
                                columns={columns2}
                                loading={false}
                                dataSource={Red_ManualUpsale?.data?.[0]?.res?.Orders}
                                scroll={{ x: 10 }}
                            // pagination={{
                            //     defaultCurrent: page,
                            //     total: Red_LeadMonthAndYear?.data?.[0]?.res?.data3,
                            //     onChange: (p) => {
                            //         setPage(p);
                            //     },
                            //     pageSize: pageSize,
                            // }}
                            />

                        </div>
                    </div>
                
                    
                </div>
            </div>
        </>
    );
};
function mapStateToProps({ Red_ManualUpsale }) {
    return { Red_ManualUpsale };
}

export default connect(mapStateToProps, ManualUpsale_Actions)(ViewUpsale);
