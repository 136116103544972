import * as yup from "yup";


const createTaskSchema = yup.object().shape({
    title: yup.string().required("Title is required").max(60,'Text length exceeded').matches(/\s+/g, "Invalid Title" ),
    summary: yup.string().required("Summary is required").matches(/\s+/g, "Invalid Summary" ) ,
    assignee: yup.string().required("User is required"),
    estimated_time: yup.date().required("Estimated time is required").min(new Date(), "Date cannot be in the past"),
    project_id: yup.string().required("Project is required"),
    priority: yup.string().required("Priority is required"),
});


export { createTaskSchema };