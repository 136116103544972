import {
    Get_Upsale_Start,
    Get_Upsale_end,
    Get_Frontsale_data,
    Get_Upsale_data,
} from '../../actions/types'


const initState = {
    AllUpsale: [],
    Allfrontsale: [],
    loading: false,
}

const Red_AllUpsale = (state = initState, action) => {
    switch (action.type) {
        case Get_Upsale_Start:
            return {
                ...state,
                loading: action.payload,
            };
        case Get_Frontsale_data:
            return {
                ...state,
                Allfrontsale: action.payload,
            };
        case Get_Upsale_data:
            return {
                ...state,
                AllUpsale: action.payload,
            };

        case Get_Upsale_end:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default Red_AllUpsale