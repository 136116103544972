import React from 'react'
import '../ClientComponents/assets/css/clientfooter.css'

function ClientFooter() {
    return (
        <>
            {/* <div className="client_footerBox">
                <ul>
                    <li><a href="">Logo</a></li>
                    <li><a href="">Mobile App</a></li>
                    <li><a href="">Animation</a></li>
                    <li><a href="">Website</a></li>
                    <li><a href="">Social Media</a></li>
                    <li><a href="">SEO</a></li>
                    <li><a href="">Portfolio</a></li>
                    <li><a href="">Packages</a></li>
                    <li><a href="">About us</a></li>
                </ul>
                <span className='client_footerTagLine'>© 2023 made with by <a href="http://logomish.com/" target='_blank'>Logo Mish</a> for a better web.</span>
            </div> */}
        </>
    )
}

export default ClientFooter